import styled from '@emotion/styled';

import {
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
} from 'lib/dropdown';
import transientOptions from 'theme/helpers';
import { dialogBoxShadow } from 'theme/utils/commonStyles';

export const Content = styled(DropdownMenuContent)`
  z-index: 3000;
  min-width: 260px;
  max-height: 65vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  background-color: ${({ theme }) => theme.palette.dina.surfaceDialogs};
  ${dialogBoxShadow};
  animation: 0.2s ease-in-out 0s 1 normal none running animate-in;
  animation: 0.2s ease-in-out 0s 1 normal none running fade-out-0;
  animation: 0.2s ease-in-out 0s 1 normal none running zoom-out-95;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-top-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-right-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-left-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-bottom-2;
`;

export const Label = styled(DropdownMenuLabel)`
  ${({ theme }) => theme.typography.dina.overline};
  display: flex;
  align-items: center;
  height: 22px;
`;

export const Separator = styled(DropdownMenuSeparator, transientOptions)<{
  inset?: boolean;
}>`
  margin-left: ${({ inset }) => (inset ? '0' : '-12px')};
  margin-right: ${({ inset }) => (inset ? '0' : '-12px')};
  margin-block: 8px;
  border-top: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const MenuItem = styled(DropdownMenuItem)`
  position: relative;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: 6px;
  gap: 6px;
  width: 100%;
  min-width: 120px;
  user-select: none;
  padding: 6px 8px;
  ${({ theme }) => theme.typography.dina.listItemLabel};
  color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  outline: none;
  &[data-highlighted] {
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.palette.dina.onFocus};
  }
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    box-shadow: none;
  }
`;

export const Checkbox = styled('input')`
  height: 18px;
  width: 18px;
  margin-right: 8px;
`;

export const SubTrigger = styled(DropdownMenuSubTrigger)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 6px;
  user-select: none;
  border-radius: 6px;
  padding: 6px 8px;
  outline: none;
  &[data-highlighted] {
    box-shadow: 0px 0px 0px 2px ${({ theme }) => theme.palette.dina.onFocus};
  }
  :focus-visible {
    outline: none;
  }
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
    box-shadow: none;
  }
  .chevron {
    margin-left: auto;
    display: flex;
    align-items: center;
  }
`;

export const SubContent = styled(DropdownMenuSubContent)`
  z-index: 3000;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
  border-radius: 6px;
  min-width: 260px;
  border: 1px solid ${({ theme }) => theme.palette.dina.borderResting};
  background-color: ${({ theme }) => theme.palette.dina.surfaceCard};
  ${dialogBoxShadow};
  animation: 0.2s ease-in-out 0s 1 normal none running animate-in;
  animation: 0.2s ease-in-out 0s 1 normal none running fade-out-0;
  animation: 0.2s ease-in-out 0s 1 normal none running zoom-out-95;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-top-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-right-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-left-2;
  animation: 0.2s ease-in-out 0s 1 normal none running slide-in-from-bottom-2;
`;

export const RadioGroup = styled(DropdownMenuRadioGroup)`
  display: flex;
  flex-direction: column;
`;

export const RadioItem = styled(DropdownMenuRadioItem)`
  display: flex;
  align-items: center;
  gap: 4px;
  height: 24px;
  position: relative;
  cursor: pointer;
  border-radius: 8px;

  .emptyRadioItemIndicator {
    height: 20px;
    width: 20px;
    border: 1px solid ${({ theme }) => theme.palette.dina.borderResting};
    border-radius: 100%;
  }

  :focus {
    outline: none;
    background-color: ${({ theme }) => theme.palette.dina.onFocusedBackground};
    .emptyRadioItemIndicator {
      outline: 2px solid ${({ theme }) => theme.palette.dina.onFocus};
      outline-offset: -2px;
      background-color: ${({ theme }) => theme.palette.dina.outlineButtonOnHover};
    }
  }
  :hover {
    background-color: ${({ theme }) => theme.palette.dina.hoverOverlay};
  }

  .radioItemIndicator {
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background-color: ${({ theme }) => theme.palette.dina.highEmphasis};
    border: 1px solid ${({ theme }) => theme.palette.dina.onFocus};
    border-radius: 100%;
  }
  .radioItemIndicator::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: ${({ theme }) => theme.palette.dina.onFocus};
  }
`;

export const Sub = styled(DropdownMenuSub)``;
