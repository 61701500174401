import Tabs, { StyleProps } from './Parts';

type TabType = string | { key: string; value: React.ReactNode };

interface TabsProps {
  /** Tabs can be an array of strings or {k: string, v: Node} */
  tabs: TabType[];
  activeTab: string;
  setActiveTab: (tab: string) => void;
  styleProps?: StyleProps;
  activeBackground?: string;
  children?: React.ReactNode;
}

export { Tabs };

export default function ContainedTabs({
  tabs,
  activeTab,
  setActiveTab,
  activeBackground,
  styleProps,
  children,
  ...rest
}: Readonly<TabsProps>) {
  return (
    <Tabs
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      defaultTab={typeof tabs[0] === 'object' ? tabs[0]?.key : tabs[0]}
      styleProps={styleProps}
      {...rest}
    >
      <Tabs.List styleProps={styleProps}>
        {tabs.map((tab) => {
          const tabKey = typeof tab === 'object' ? tab.key : tab;

          return (
            <Tabs.Trigger
              key={tabKey}
              active={activeTab === tabKey}
              id={tabKey}
              activeBackground={activeBackground}
            >
              {typeof tab === 'object' ? tab.value : tab}
            </Tabs.Trigger>
          );
        })}
      </Tabs.List>
      {children}
    </Tabs>
  );
}
