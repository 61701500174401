import { atom, useAtom, useAtomValue } from 'jotai';
import { focusAtom } from 'jotai-optics';
import { flatten, keyBy } from 'lodash';

import { MemberTypeEnum } from 'types/graphqlTypes';
import { User, UserMProperties } from 'types/members';

interface MembersDialog {
  open: boolean;
  startWith: User[] | [];
  onOk?: (newMembers: User[] | [], messageAssign: string, canFilterByAndLogic: boolean) => void;
  onCancel?: () => void;
  singleChoice?: boolean;
  variant?: 'contact' | 'user' | 'team' | 'department' | 'all';
  dialogTitle?: string;
  removeTooltipText?: string;
  showMessage?: boolean;
  subHeader?: string;
  buttonText?: string;
  disableRemoveOnlyAssignee?: boolean;
  disabledRemoveTooltipText?: string;
  injectedMembers?: User[];
  matchAll?: boolean;
  doCallApi?: boolean;
}

const membersAtom = atom<Record<'user' | 'team' | 'department' | 'contact', User[]>>({
  user: [],
  team: [],
  department: [],
  contact: [],
});
export const useMembers = () => useAtom(membersAtom);

const allMembersAtom = atom<User[]>((get) => {
  const members = get(membersAtom);
  return flatten(Object.values(members));
});

const allMembersKeyedByIdAtom = atom((get) => {
  const members = get(allMembersAtom);
  return keyBy(members, 'mId');
});

export const useAllMembers = () => useAtom(allMembersAtom);

export const useAllMembersKeyed = () => useAtom(allMembersKeyedByIdAtom);

export const usersAtom = focusAtom(membersAtom, (optic) => optic.prop('user'));

const usersKeyedAtom = atom((get) => {
  const users = get(usersAtom);
  return keyBy(users, 'mId');
});

export const useUsers = () => useAtom(usersAtom);
export const useUsersKeyed = () => useAtom(usersKeyedAtom);

const DUMMY_USER_METADATA: UserMProperties = {
  firstName: 'some',
  email: 'someone@somwhere.com',
  surname: 'one',
  jobTitle: 'User',
  username: 'someone',
  __typename: 'ContactType',
};
const DUMMY_USER_METADATA_JSON = JSON.stringify(DUMMY_USER_METADATA);

function createDummyUser(userId: string): User {
  return {
    mId: userId,
    mRefId: userId,
    mTitle: 'someone',
    mType: MemberTypeEnum.User,
    mdfId: 'user-mdf',
    metadata: DUMMY_USER_METADATA_JSON,
    mProperties: DUMMY_USER_METADATA,
  };
}

const dummyUserMap = new Map<string, User>();

/**
 * Gets a dummy user from a user ID. Will return the same object every time for the same ID.
 * @param userId The ID of the user to get a dummy user for
 * @returns      The dummy user
 */
export function getDummyUser(userId: string): User {
  let user = dummyUserMap.get(userId);
  if (!user) {
    user = createDummyUser(userId);
    dummyUserMap.set(userId, user);
  }
  return user;
}

export const teamsAtom = focusAtom(membersAtom, (optic) => optic.prop('team'));
export const useTeams = () => useAtom(teamsAtom);

export const departmentsAtom = focusAtom(membersAtom, (optic) => optic.prop('department'));
export const useDepartments = () => useAtom(departmentsAtom);

const usersMapAtom = atom((get) => {
  const users = get(usersAtom);

  return keyBy(users, 'mId');
});
export const useUsersMap = () => useAtomValue(usersMapAtom);

/* Contact atom */
const contactAtom = atom((get) => {
  const members = get(membersAtom);
  return members.contact;
});
export const useContact = () => useAtomValue(contactAtom);

const unreadConversations = atom([]);
export const useUnreadConversations = () => useAtom(unreadConversations);

const membersDialogDefaults = {
  open: false,
  startWith: [],
  onOk: undefined,
  onCancel: undefined,
  singleChoice: false,
  variant: 'all',
  dialogTitle: 'Assign to story',
  removeTooltipText: 'Remove',
  subHeader: 'Selected:',
  buttonText: 'Confirm',
  disableRemoveOnlyAssignee: false,
  disabledRemoveTooltipText: '',
  injectedMembers: undefined, // Can be used to provide external members, such as categories.
  matchAll: false,
} as MembersDialog;

const membersDialog = atom<MembersDialog>({
  ...membersDialogDefaults,
});

const membersOpenDialog = atom<boolean>(false);
const membersDialogAtom = atom(
  (get) => get(membersDialog),
  (_get, set, val: MembersDialog) => {
    set(membersDialog, val);
    const { open } = val;
    if (open) {
      set(membersOpenDialog, true);
    }
  },
);

const membersFilterMatchAll = atom(false);
const membersFilterMatchAllAtom = atom(
  (get) => get(membersFilterMatchAll),
  (get, set, val: boolean) => {
    set(membersFilterMatchAll, val);
  },
);

const openDialogAtom = atom(
  (get) => get(membersOpenDialog),
  (get, set, val: boolean) => {
    set(membersOpenDialog, val);
    if (!val) {
      // Clear after 500 ms to avoid state hopping around while animating out
      setTimeout(() => {
        set(membersDialog, { ...membersDialogDefaults });
      }, 500);
    }
  },
);

export const useMembersDialog = () => useAtom(membersDialogAtom);
export const useMembersOpenDialog = () => useAtom(openDialogAtom);
export const useMembersFilterMatchAll = () => useAtom(membersFilterMatchAllAtom);
