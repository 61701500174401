import { css } from '@emotion/react';
import styled from '@emotion/styled';
import FilledInput from '@material-ui/core/FilledInput';

import Slot from 'lib/slot';
import transientOptions from 'theme/helpers';

import { TextProps } from './Text';

const defaultColor = 'mediumEmphasis';
const defaultFontStyles = 'body1';

interface TextStyleProps extends Omit<TextProps, 'truncate | capitalize | uppercase'> {
  $truncate?: boolean;
  $capitalize?: boolean;
  $uppercase?: boolean;
}

export const TextWrapper = styled(Slot, transientOptions)<TextStyleProps>`
  margin: 0;
  ${({ theme, variant }) => theme?.typography?.dina[variant ?? defaultFontStyles]};
  color: ${({ theme, color }) => theme?.palette?.dina[color ?? defaultColor]};

  // Remove underline if the text is polymorphic as an anchor
  text-decoration-line: none;

  ${({ $capitalize }) => {
    return (
      $capitalize &&
      css`
        text-transform: capitalize;
      `
    );
  }};

  ${({ $uppercase }) => {
    return (
      $uppercase &&
      css`
        text-transform: uppercase;
      `
    );
  }};

  ${({ $truncate }) => {
    return (
      $truncate &&
      css`
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      `
    );
  }};
`;

// Editable Text
export const Input = styled(FilledInput, transientOptions)<{
  $variant: TextProps['variant'];
  $color: TextProps['color'];
  $borderColor?: TextProps['color'];
  $textAlign?: 'left' | 'center' | 'right';
}>`
  width: 100%;
  .MuiFilledInput-input {
    height: auto;
    padding: 6px 8px;
    ${({ theme, $variant }) => theme?.typography?.dina[$variant ?? defaultFontStyles]};
    color: ${({ theme, $color }) => theme?.palette?.dina[$color ?? defaultColor]};
    border: 1px solid
      ${({ theme, $borderColor = 'inputBorderResting' }) => theme.palette.dina[$borderColor]};
    border-radius: 6px;
    background: ${({ theme }) => theme.palette.dina.inputBackground};
    text-align: ${({ $textAlign = 'left' }) => $textAlign};
  }

  &.MuiFilledInput-multiline {
    padding: 0;
  }

  &.MuiFilledInput-underline {
    &::before,
    &::after {
      border: none;
    }
  }
`;
