import { useMemo } from 'react';
import { TZDate } from '@date-fns/tz';

import useDateComparisonUtils from './useDateComparisonUtils';
import useDateDifferenceUtils from './useDateDifferenceUtils';
import useDateFormattingUtils from './useDateFormattingUtils';
import useDateManipulationUtils from './useDateManipulationUtils';

export type DateType = Date | string | number;
export type TimezoneConverter = (date: DateType) => TZDate;

export const toTimeZoneDate = (timezone: string) => (date: DateType) => {
  if (typeof date === 'string') return new TZDate(date, timezone);
  if (typeof date === 'number') return new TZDate(date, timezone);
  return new TZDate(date, timezone);
};

const useDateTimeUtils = () => {
  const dateManipulationUtils = useDateManipulationUtils();
  const comparisonUtils = useDateComparisonUtils();
  const formattingUtils = useDateFormattingUtils();
  const differenceUtils = useDateDifferenceUtils();

  const memoizedUtils = useMemo(
    () => ({
      ...comparisonUtils,
      ...dateManipulationUtils,
      ...formattingUtils,
      ...differenceUtils,
    }),
    [comparisonUtils, dateManipulationUtils, formattingUtils, differenceUtils],
  );

  return memoizedUtils;
};

export default useDateTimeUtils;
