/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from 'react';
import { getUrl } from 'aws-amplify/storage';

import { isProductionMode } from 'utils/signedCookie';

import { useSignedCookie } from '../store';

import useSettingsValue from './useSettingsValue';

export const createImageUrl = (key: string) =>
  `https://${window.location.hostname}/datastore/${key}`;

/**
 * A hook to determine if the production environment is valid based on certain conditions.
 *
 * @returns `true` if the production environment is considered valid, otherwise `false`.
 */
export const useIsProdValid = () => {
  const [signedCookie] = useSignedCookie();
  const [getSettingsValue] = useSettingsValue();
  const isCookieDisabled = getSettingsValue('app.disableSignedCookie') === 'true';

  const isProdValid = useMemo(
    () => isProductionMode && !isCookieDisabled && signedCookie.length > 0,
    [],
  );

  return isProdValid;
};

/**
 * Custom hook for obtaining an image URL based on the provided key.
 *
 * This hook is responsible for fetching the corresponding image URL given the key.
 * It takes into account the production mode, signed cookie settings, and asynchronously
 * fetches the URL from storage.
 *
 * @param {string} key - The key for which the image URL is generated.
 * @returns {string | null} The generated image URL, or null if not available.
 *
 */

const useImageUrl = (key: string): string | null => {
  const isProdValid = useIsProdValid();

  const [url, setUrl] = useState<string | null>(null);
  const isSubscribed = useRef<boolean | null>(null);

  useEffect(() => {
    isSubscribed.current = true;

    void (async () => {
      try {
        if (!key) return;

        const signedUrl = isProdValid
          ? createImageUrl(key)
          : (await getUrl({ path: key })).url.href;

        if (isSubscribed.current) setUrl(signedUrl);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error('Error fetching image: ', err);
      }
    })();

    return () => {
      isSubscribed.current = false;
    };
  }, [key, isProdValid]);

  return url;
};

export default useImageUrl;
