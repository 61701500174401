import { useCallback, useState } from 'react';
import styled from '@emotion/styled';

import useGetGroupPolicy from 'api/useGetGroupPolicy';
import { ExportButton } from 'components/buttons/ExportButton';
import { ImportButton } from 'components/buttons/ImportButton';
import Divider from 'components/divider';
import ChoiceField from 'components/mdfEditor/fields/choice/ChoiceField';
import { MdfEditor } from 'components/mdfEditor/MdfEditor';
import Scrollbar from 'components/scrollbar';
import SplitBar from 'components/split';
import Tabs from 'components/tabs/contained';
import Text from 'components/text/Text';
import Tooltip from 'components/tooltip';
import { NameMappings } from 'features/mdf/conversion';
import { jsonToMdf } from 'features/mdf/jsonToMdf';
import { ExistingFieldInfo, useEditFieldValueDialog } from 'features/mdf/mdf-utils';
import { mdfToJson } from 'features/mdf/mdfToJson';
import useMetadata from 'hooks/useMetadata';
import { Flex, VStack } from 'layouts/box/Box';
import { FieldTypeEnum, LayoutSettings, Mdf, MdfField, Views, ViewTypes } from 'types/graphqlTypes';

import { ConfigFieldDialog } from './components/ConfigFieldDialog';
import { EditFieldLayout } from './components/EditFieldLayout';
import { EditFieldModel } from './components/EditFieldModel';
import NewFieldDialog from './components/NewFieldDialog';
import { usePermissionDialog } from './EditPermissionDialog';
import { getDefaultLayoutSettings, getDefaultPayload, normalizeField } from './utils';

import {
  ContentWrapper,
  MdfEditorWrapper,
  Payload,
  PayloadInfo,
  PayloadTitle,
  PayloadWrapper,
  RightHeader,
  TabsWrapper,
} from './styled';

interface Tab {
  label: string;
  id: ViewTypes | 'model';
}

const Wrapper = styled.div`
  width: 100%;
`;

interface EditMdfProps {
  mdf: Mdf;
  onChange?: (updatedMdf: Mdf | null, originalMdf: Mdf | null) => void;
  /** Maps from sub-type IDs to sub-type names (needed when importing/exporting) */
  nameMappings: NameMappings;
  /**
   * Maps from field ID of existing fields in all MDFs of the system to the type of that field
   * and where it is used.
   */
  existingFieldInfoMap: Readonly<Record<string, ExistingFieldInfo>>;
  doOpenOptionList?: (id: string) => void;
  reportError: (schemaId: string, error: string) => void;
}

const tabs: Tab[] = [
  { label: 'Model', id: 'model' },
  { label: 'Layout defaults', id: 'default' },
];

const NO_FIELDS: readonly MdfField[] = Object.freeze([]);

export function EditMdf({
  mdf,
  onChange,
  nameMappings,
  existingFieldInfoMap,
  doOpenOptionList,
  reportError,
}: Readonly<EditMdfProps>) {
  const [newFieldOpen, setNewFieldOpen] = useState(false);
  const [configField, setConfigField] = useState<MdfField | null>(null);
  const [, showEditFieldDialog] = useEditFieldValueDialog();
  const [, openPermissionDialog] = usePermissionDialog();
  const [selectedView, setSelectedView] = useState<ViewTypes | 'model'>('model');
  const { groupPolicies } = useGetGroupPolicy();
  const { metadata, errorMap, errorTooltip, updateFieldValues, removeValue } = useMetadata(
    mdf,
    getDefaultPayload(mdf),
    selectedView !== 'model' ? selectedView : undefined,
  );

  const indexes = tabs.map((t) => t.id);
  const labels = tabs.map((t) => t.label);

  const setFields = useCallback(
    (newFields: MdfField[]) => {
      if (mdf && onChange) {
        onChange({ ...mdf, fields: newFields }, mdf);
      }
    },
    [mdf, onChange],
  );

  const updateField = useCallback(
    (field: MdfField) => {
      if (mdf && onChange) {
        const index = mdf.fields.findIndex((f) => f.fieldId === field.fieldId);
        const otherFields = mdf.fields.filter((f) => f.fieldId !== field.fieldId);
        const updatedFields = [...otherFields];
        updatedFields.splice(index, 0, field);

        onChange({ ...mdf, fields: updatedFields }, mdf);
      }
    },
    [mdf, onChange],
  );

  const onUpdateFieldAndLabel = useCallback(
    (field: MdfField, label: string) => {
      if (mdf && onChange) {
        const index = mdf.fields.findIndex((f) => f.fieldId === field.fieldId);
        const otherFields = mdf.fields.filter((f) => f.fieldId !== field.fieldId);
        const updatedFields = [...otherFields];
        updatedFields.splice(index, 0, field);

        const sIndex = mdf.views.default.findIndex((s) => s.fieldId === field.fieldId);
        const updatedWithLabel = mdf.views.default[sIndex]
          ? { ...mdf.views.default[sIndex], label }
          : null;
        if (updatedWithLabel) {
          const otherSettings = mdf.views.default.filter((s) => s.fieldId !== field.fieldId);
          const updatedSettings = [...otherSettings];
          updatedSettings.splice(sIndex, 0, updatedWithLabel);

          const updatedViews: Views = {
            ...mdf.views,
            default: updatedSettings,
          };

          onChange({ ...mdf, fields: updatedFields, views: updatedViews }, mdf);
        } else {
          onChange({ ...mdf, fields: updatedFields }, mdf);
        }
      }
    },
    [mdf, onChange],
  );

  const updatePermissions = useCallback(
    (fieldId: string, permissions: { read: string[]; write: string[] }) => {
      if (mdf && onChange) {
        onChange(
          {
            ...mdf,
            permissions: {
              read: { ...mdf.permissions.read, [fieldId]: permissions.read },
              write: { ...mdf.permissions.write, [fieldId]: permissions.write },
            },
          },
          mdf,
        );
      }
    },
    [mdf, onChange],
  );

  const addNewField = useCallback(
    (field: MdfField) => {
      if (mdf && onChange) {
        const normalized = normalizeField(field);
        const newSettings = getDefaultLayoutSettings(field);

        onChange(
          {
            ...mdf,
            fields: [...mdf.fields, normalized],
            views: {
              ...mdf.views,
              default: [...mdf.views.default, newSettings],
            },
          },
          mdf,
        );
      }
    },
    [mdf, onChange],
  );

  const onDeleteField = useCallback(
    (fieldId: string) => {
      if (mdf && onChange) {
        const fields = mdf.fields ?? NO_FIELDS;
        const updatedFields = fields.filter((f) => f.fieldId !== fieldId);

        onChange({ ...mdf, fields: updatedFields }, mdf);
        removeValue(fieldId);
      }
    },
    [mdf, removeValue, onChange],
  );

  const updateLayoutSettings = useCallback(
    (updatedSettings: LayoutSettings, view: ViewTypes) => {
      if (mdf && onChange) {
        const settings = [...mdf.views[view]];
        const index = settings.findIndex((s) => s.fieldId === updatedSettings.fieldId);
        if (index >= 0) {
          settings.splice(index, 1, updatedSettings);
        } else {
          settings.push(updatedSettings);
        }
        onChange({ ...mdf, views: { ...mdf.views, [view]: settings } }, mdf);
      }
    },
    [mdf, onChange],
  );

  const onShowFieldEditor = useCallback(
    (field: MdfField) => {
      if (mdf) {
        showEditFieldDialog({
          startValue: field.defaultValue.value,
          fieldId: field.fieldId,
          headerText: `Edit ${field.fieldId}`,
          viewType: 'default',
          mdf,
          editMode: true,
          onConfirm: (v) => updateField({ ...field, defaultValue: { value: v } }),
        });
      }
    },
    [mdf, showEditFieldDialog, updateField],
  );

  const onShowPermissionDialog = useCallback(
    (fieldId: string) => {
      openPermissionDialog({
        open: true,
        fieldId,
        read: mdf?.permissions.read[fieldId],
        write: mdf?.permissions.write[fieldId],
        setPermissions: updatePermissions,
      });
    },
    [mdf?.permissions.read, mdf?.permissions.write, openPermissionDialog, updatePermissions],
  );

  const handleTabChange = (label: string) => {
    const selectedTab = tabs.find((t) => t.label === label) as Tab;
    setSelectedView(selectedTab.id);
  };

  const onImportAsync = useCallback(
    async (file: File) => {
      const fileText = await file.text();
      const fileMdf = jsonToMdf(
        fileText,
        nameMappings,
        existingFieldInfoMap,
        groupPolicies.filter((p) => !!p.mRefId).map((p) => p.mRefId as string),
      );
      const { fields, views, permissions } = fileMdf;
      // We don't want to change the id, label, isSubType and flavor properties
      const updatedMdf: Mdf = { ...mdf, fields, permissions, views };
      onChange?.(updatedMdf, mdf);
    },
    [mdf, onChange],
  );

  return (
    <Wrapper>
      <SplitBar
        split="vertical"
        style={{
          height: '100%',
        }}
        primary="first"
        pane1Style={{
          minWidth: '60%',
          maxWidth: '80%',
        }}
        pane2Style={{
          minWidth: '20%',
          maxWidth: '100%',
        }}
      >
        <ContentWrapper>
          <TabsWrapper>
            <Flex margin="8px">
              <Tabs
                setActiveTab={handleTabChange}
                activeTab={labels[indexes.indexOf(selectedView)]}
                tabs={labels}
              />
            </Flex>
            <ChoiceField
              editorId="editMdf"
              fieldModel={{
                fieldId: 'layout-choice',
                type: FieldTypeEnum.choice,
                defaultValue: { value: null },
                alternatives: [
                  {
                    id: 'order_grid',
                    label: 'Order grid',
                    value: 'order_grid',
                  },
                  {
                    id: 'order_form',
                    label: 'Order form',
                    value: 'order_form',
                  },
                  {
                    id: 'story_create',
                    label: 'Story create',
                    value: 'story_create',
                  },
                  {
                    id: 'story_view',
                    label: 'Story view',
                    value: 'story_view',
                  },
                  {
                    id: 'search_view',
                    label: 'Search',
                    value: 'search_view',
                  },
                ],
              }}
              value={selectedView}
              setValue={(val) => setSelectedView(val ? (val as ViewTypes | 'model') : 'model')}
              fieldSettings={null}
              style={{
                width: '180px',
                marginTop: '2px',
              }}
              defaultFieldSettings={{
                fieldId: 'layout-choice',
                label: '',
                hint: '',
                visible: true,
              }}
              errorMessage={undefined}
              placeholder="Override layout"
              view="default"
            />
            <ExportButton
              width={90}
              height={32}
              disabled={!mdf}
              title="Exports the whole schema and opens it in a new browser tab"
              getExportData={() => ({
                fileName: `${mdf.label}.json`,
                content: mdfToJson(mdf, nameMappings),
              })}
            />
            <ImportButton
              width={90}
              height={32}
              confirmMessage={
                mdf?.fields.length
                  ? 'Import will replace all the current content in the schema'
                  : undefined
              }
              disabled={!onChange}
              onImportAsync={onImportAsync}
              title="Replaces completely the whole schema with a schema from a file"
              reportError={(error) => reportError(mdf.id, error)}
            />
          </TabsWrapper>
          <Divider className="horizontal" />
          {mdf && (
            <VStack height="60vh" gap="40px">
              <Scrollbar
                valueChanged={undefined}
                closeToBottom={undefined}
                top={undefined}
                bottom={undefined}
                dark={undefined}
                showHorizontal={undefined}
              >
                <div>
                  {selectedView === 'model' && (
                    <EditFieldModel
                      selectedMdf={mdf}
                      fields={mdf?.fields ?? NO_FIELDS}
                      onShowEditFieldDialog={onShowFieldEditor}
                      onShowPermissionDialog={onShowPermissionDialog}
                      onUpdateField={updateField}
                      onUpdateFieldAndLabel={onUpdateFieldAndLabel}
                      onDeleteField={onDeleteField}
                      onShowConfig={(field) => setConfigField(field)}
                      setFields={setFields}
                      onAddFieldClick={() => setNewFieldOpen(true)}
                      doOpenOptionList={(id: string) => {
                        doOpenOptionList?.(id);
                      }}
                    />
                  )}
                  {selectedView !== 'model' && (
                    <EditFieldLayout
                      selectedView={selectedView}
                      fields={mdf.fields}
                      views={mdf.views}
                      onUpdateSettings={updateLayoutSettings}
                    />
                  )}
                </div>
              </Scrollbar>
            </VStack>
          )}
        </ContentWrapper>
        {mdf ? (
          <VStack width="100%" height="100%">
            <VStack width="100%" height="100%">
              <RightHeader>
                <Text variant="overline" color="highEmphasis">
                  Previewing {selectedView}
                </Text>
              </RightHeader>
              <Scrollbar>
                <MdfEditorWrapper>
                  <MdfEditor
                    view={selectedView === 'model' ? 'default' : selectedView}
                    fields={mdf.fields}
                    defaultLayoutSettings={mdf.views.default}
                    layoutSettings={
                      selectedView === 'model' ? mdf.views.default : mdf.views[selectedView]
                    }
                    updateFieldValue={updateFieldValues}
                    metadata={metadata}
                    errorMap={errorMap}
                    permissions={mdf.permissions}
                  />
                </MdfEditorWrapper>
              </Scrollbar>
            </VStack>

            <PayloadWrapper>
              <Tooltip title={errorTooltip ?? ''}>
                <PayloadTitle>
                  <Text variant="overline" color="highEmphasis">
                    Payload JSON
                  </Text>
                  {errorTooltip && <PayloadInfo className="skipOverride" />}
                </PayloadTitle>
              </Tooltip>
              <Payload>
                <code className="json">{JSON.stringify(metadata, null, 2)}</code>
              </Payload>
            </PayloadWrapper>
          </VStack>
        ) : (
          <div />
        )}
      </SplitBar>
      <NewFieldDialog
        open={newFieldOpen}
        setOpen={setNewFieldOpen}
        onNewField={addNewField}
        fields={mdf?.fields ?? NO_FIELDS}
      />
      <ConfigFieldDialog
        open={Boolean(configField)}
        setOpen={(val: boolean) => {
          if (!val) {
            setConfigField(null);
          }
        }}
        onConfirm={(updatedField) => {
          updateField(updatedField);
          setConfigField(null);
        }}
        fieldToConfig={configField}
      />
    </Wrapper>
  );
}
