import { memo, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import styled from '@emotion/styled';

import RadioButton from 'components/buttons/radioButton';
import Divider from 'components/divider';
import Scrollbar from 'components/scrollbar';
import Switch from 'components/switch/Switch';
import Text from 'components/text';
import ConfigContext from 'contexts/configContext';
import usePlatformWorkflow from 'hooks/usePlatformWorkflow';
import { Box, HStack, VStack } from 'layouts/box/Box';
import { useSelectedMenuSystemSettings } from 'screens/main/components/header/navbar/settings/atoms';
import { useChangedPlatformWorkflowSettings } from 'screens/main/components/header/navbar/settings/atomsTs';
import { KanbanBoardStateType } from 'types';
import { InstanceStateType, InstanceStateViewType } from 'types/graphqlTypes';
import returnStates from 'utils/statusBoards/returnStates';

export const RadioButtonWithLabel = styled('label')`
  display: flex;
  align-items: center;
  text-transform: capitalize;
  gap: 0.5rem;
  cursor: pointer;
`;

export const StyledGroupWrapper = styled(VStack)`
  .MuiSwitch-root {
    margin-left: -12px;
  }
`;

const StateGroup = ({
  states,
  selectedState,
  onStateChange,
}: {
  states: KanbanBoardStateType[];
  selectedState: string | null;
  onStateChange: (state: string) => void;
}) => {
  return (
    <VStack gap="0.5rem" overflow="visible" padding="0.5rem 0">
      {states.map((state) => (
        <RadioButtonWithLabel
          key={state.id}
          style={{ display: 'flex', alignItems: 'center' }}
          onClick={() => onStateChange(state.id)}
        >
          <RadioButton
            size={20}
            key={state.id}
            value={state.id}
            selected={selectedState === state.id}
          />
          {state.name}
        </RadioButtonWithLabel>
      ))}
    </VStack>
  );
};

const WorkflowSettings = () => {
  const [selectedMenu] = useSelectedMenuSystemSettings() as unknown as [string];
  const {
    kanbanBoardStates = [] as InstanceStateType[],
    kanbanBoardViews = [] as InstanceStateViewType[],
  } = useContext(ConfigContext);

  const states = useMemo(
    () =>
      returnStates(
        /** as long as linear platforms have the mRefId linear, this should work */
        selectedMenu.includes('linear') ? 'status-linear' : 'status-SoMe',
        kanbanBoardStates,
        kanbanBoardViews,
      ) as KanbanBoardStateType[],
    [kanbanBoardStates, kanbanBoardViews, selectedMenu],
  );

  const defaultState = useMemo(() => states[0], [states]);

  const { getApprovalState, getScheduledState } = usePlatformWorkflow();

  const { initialApprovalState, initialScheduledState } = useMemo(
    () => ({
      initialApprovalState: getApprovalState(selectedMenu),
      initialScheduledState: getScheduledState(selectedMenu),
    }),
    [getApprovalState, getScheduledState, selectedMenu],
  );

  const [scheduleWorkflowOn, setScheduleWorkflowOn] = useState(!!initialScheduledState);
  const [stateOnSchedule, setStateOnSchedule] = useState<string | null>(
    getScheduledState(selectedMenu) ?? defaultState?.id ?? null,
  );

  const [approvalWorkflowOn, setApprovalWorkflowOn] = useState(!!initialApprovalState);
  const [stateOnApproval, setStateOnApproval] = useState<string | null>(
    getApprovalState(selectedMenu) ?? defaultState?.id ?? null,
  );

  const [changedPlatformWorkflowSettings, setChangedPlatformWorkflowSettings] =
    useChangedPlatformWorkflowSettings();

  const onScheduledStateChange = useCallback(
    (state: string | null) => {
      setChangedPlatformWorkflowSettings((prev) => ({
        ...prev,
        [selectedMenu]: {
          stateOnSchedule: state ?? undefined,
          approvalState: approvalWorkflowOn ? stateOnApproval ?? undefined : undefined,
        },
      }));
      setStateOnSchedule(state);
    },
    [approvalWorkflowOn, selectedMenu, setChangedPlatformWorkflowSettings, stateOnApproval],
  );

  const onApprovalStateChange = useCallback(
    (state: string | null) => {
      setChangedPlatformWorkflowSettings((prev) => ({
        ...prev,
        [selectedMenu]: {
          stateOnSchedule: scheduleWorkflowOn ? stateOnSchedule ?? undefined : undefined,
          approvalState: state ?? undefined,
        },
      }));
      setStateOnApproval(state);
    },
    [scheduleWorkflowOn, selectedMenu, setChangedPlatformWorkflowSettings, stateOnSchedule],
  );

  const toggleScheduleWorkflow = useCallback(() => {
    if (!scheduleWorkflowOn) {
      setScheduleWorkflowOn(true);
      setStateOnSchedule(defaultState.id);
      onScheduledStateChange(defaultState?.id);
    } else {
      setScheduleWorkflowOn(false);
      setStateOnSchedule(null);
      onScheduledStateChange(null);
    }
  }, [scheduleWorkflowOn, onScheduledStateChange, defaultState.id]);

  const toggleApprovalWorkflow = useCallback(() => {
    if (!approvalWorkflowOn) {
      setApprovalWorkflowOn(true);
      setStateOnApproval(defaultState.id);
      onApprovalStateChange(defaultState?.id);
    } else {
      setApprovalWorkflowOn(false);
      setStateOnApproval(null);
      onApprovalStateChange(null);
    }
  }, [approvalWorkflowOn, defaultState.id, onApprovalStateChange]);

  useEffect(() => {
    // reset all local states when the selected menu changes
    setScheduleWorkflowOn(!!initialScheduledState);
    setStateOnSchedule(initialScheduledState ?? defaultState?.id ?? null);
    setApprovalWorkflowOn(!!initialApprovalState);
    setStateOnApproval(initialApprovalState ?? defaultState?.id ?? null);
  }, [defaultState?.id, initialApprovalState, initialScheduledState]);

  useEffect(() => {
    /**  check if the selected menu has a scheduled state or approval state in
     * changedPlatformWorkflowSettings and reset local state based on that and return */
    if (changedPlatformWorkflowSettings[selectedMenu]) {
      setScheduleWorkflowOn(!!changedPlatformWorkflowSettings[selectedMenu].stateOnSchedule);
      setStateOnSchedule(changedPlatformWorkflowSettings[selectedMenu].stateOnSchedule ?? null);
      setApprovalWorkflowOn(!!changedPlatformWorkflowSettings[selectedMenu].approvalState);
      setStateOnApproval(changedPlatformWorkflowSettings[selectedMenu].approvalState ?? null);
    } else {
      // reset all local states when the selected menu changes
      setScheduleWorkflowOn(!!initialScheduledState);
      setStateOnSchedule(initialScheduledState ?? defaultState?.id ?? null);
      setApprovalWorkflowOn(!!initialApprovalState);
      setStateOnApproval(initialApprovalState ?? defaultState?.id ?? null);
    }
  }, [
    changedPlatformWorkflowSettings,
    defaultState?.id,
    initialApprovalState,
    initialScheduledState,
    selectedMenu,
  ]);

  if (selectedMenu === 'linear')
    return (
      <Box container height="100%" width="100%" alignItems="flex-start" padding="1rem">
        <Text variant="captionRegular">
          No other settings are available for the selected platform
        </Text>
      </Box>
    );

  return (
    <Scrollbar>
      <Box
        container
        height="100%"
        width="100%"
        padding="1rem"
        flexDirection="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap="0.5rem"
        overflow="visible"
      >
        <StyledGroupWrapper gap="0.5rem" overflow="visible">
          <Text variant="h7" color="highEmphasis">
            Scheduling Workflow
          </Text>
          <Text variant="caption" color="mediumEmphasis">
            Automatically change the Workflow State when an Instance is scheduled.
          </Text>
          <HStack overflow="visible">
            <Switch
              selected={scheduleWorkflowOn}
              onClick={toggleScheduleWorkflow}
              disabled={false}
            />
            <Text variant="listItemLabel" color="highEmphasis">
              Change Workflow State when Scheduling
            </Text>
          </HStack>
          {scheduleWorkflowOn && (
            <Box container padding="0 0 0 3rem" flexDirection="column" alignItems="flex-start">
              <Text variant="overline">
                When scheduling, automatically change Workflow State to:
              </Text>
              <StateGroup
                states={states}
                selectedState={stateOnSchedule}
                onStateChange={onScheduledStateChange}
              />
            </Box>
          )}
        </StyledGroupWrapper>

        <Divider flexItem style={{ width: '100%', height: '1px' }} />

        <StyledGroupWrapper gap="0.5rem" overflow="visible">
          <Text variant="h7" color="highEmphasis">
            Approval Workflow
          </Text>
          <Text variant="caption" color="mediumEmphasis">
            Require approval from designated User Groups before publishing an Instance.
          </Text>
          <HStack overflow="visible">
            <Switch
              selected={approvalWorkflowOn}
              onClick={toggleApprovalWorkflow}
              disabled={false}
            />
            <Text variant="listItemLabel" color="highEmphasis">
              Require Approval before Publishing
            </Text>
          </HStack>
          {approvalWorkflowOn && (
            <Box container flexDirection="column" padding="0 0 0 3rem" alignItems="flex-start">
              <Text variant="overline">Publishing can only be done when Workflow State is: </Text>
              <StateGroup
                states={states}
                selectedState={stateOnApproval}
                onStateChange={onApprovalStateChange}
              />
            </Box>
          )}
        </StyledGroupWrapper>
      </Box>
    </Scrollbar>
  );
};

export default memo(WorkflowSettings);
