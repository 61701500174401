import { useMemo } from 'react';
import { gql, useQuery } from '@apollo/client';
import { isEmpty, keyBy } from 'lodash';

import useGetPlatforms from 'hooks/useGetPlatforms';
import { MdfId, MdfIds, MTypeToMdfId, SupportedMetadataTypes } from 'types';
import { GetMdfsInput, Mdf, MdfType, MemberTypeEnum } from 'types/graphqlTypes';
import { getSubtypeValue } from 'utils/mdf/utils';

import { STANDARD_MDF_KEYS } from '../commonKeys';

import { toMdfDto } from './useCreateMdf';

export const GET_MDFS = gql`
  query GetMdfs($input: GetMdfsInput) {
    getMdfs(input: $input) {
      ${STANDARD_MDF_KEYS}
    }
  }
`;

export interface GetMdfs {
  getMdfs: MdfType[];
}

interface GetInput {
  input: GetMdfsInput;
}

export const isSupportedMetadata = (mType: MemberTypeEnum): mType is SupportedMetadataTypes => {
  return MTypeToMdfId[mType as SupportedMetadataTypes] !== undefined;
};

export interface MdfsGrouped {
  defaults: Mdf[];
  blocks: Mdf[];
  instances: Mdf[];
  subTypes: Mdf[];
  custom: Mdf[];
}

export const mdfCategories = ['defaults', 'custom', 'instances', 'blocks', 'subTypes'] as const;
Object.freeze(mdfCategories);

export type MdfCategory = (typeof mdfCategories)[number];

export function isMdfCategory(s: string): s is MdfCategory {
  return mdfCategories.indexOf(s as MdfCategory) >= 0;
}

export const useGetMdfCategory = () => {
  const { platformVariants } = useGetPlatforms(new Date());
  return (mdf: Mdf): MdfCategory => {
    if (MdfIds.includes(mdf.id as MdfId)) {
      return 'defaults';
    } else if (mdf.isSubtype) {
      return 'subTypes';
    } else if (platformVariants[mdf.id]) {
      return 'instances';
    } else if (mdf.id.startsWith('block-')) {
      return 'blocks';
    } else {
      return 'custom';
    }
  };
};

const defaultProps: GetMdfsInput = { all: true };

export const useGetMdfs = (props: GetMdfsInput = defaultProps) => {
  const getMdfCategory = useGetMdfCategory();
  const { data, error, loading, refetch } = useQuery<GetMdfs, GetInput>(GET_MDFS, {
    variables: {
      input: {
        ...props,
      },
    },
    fetchPolicy: 'cache-first',
    skip: !props || isEmpty(props),
  });

  const mdfs = useMemo(() => {
    return (data?.getMdfs ?? []).map(toMdfDto);
  }, [data?.getMdfs]);

  const mdfsSeparated: MdfsGrouped = useMemo(() => {
    const defaults: Mdf[] = [];
    const custom: Mdf[] = [];
    const instances: Mdf[] = [];
    const blocks: Mdf[] = [];
    const subTypes: Mdf[] = [];
    const result = {
      defaults,
      blocks,
      custom,
      subTypes,
      instances,
    };
    if (!data?.getMdfs)
      return { defaults: [], instances: [], custom: [], subTypes: [], blocks: [] };
    for (const mdf of mdfs) {
      result[getMdfCategory(mdf)].push(mdf);
    }
    return result;
  }, [data, getMdfCategory]);

  const subTypesByValue = useMemo(
    () => keyBy(mdfsSeparated.subTypes, getSubtypeValue),
    [mdfsSeparated.subTypes],
  );

  const mdfsByMType: Record<SupportedMetadataTypes, Mdf | undefined> = useMemo(() => {
    const result: Partial<Record<SupportedMetadataTypes, Mdf | undefined>> = {};
    (Object.keys(MTypeToMdfId) as Array<SupportedMetadataTypes>).forEach(
      (key) => (result[key] = mdfs.find((mdf) => mdf.id === MTypeToMdfId[key])),
    );
    return result as Record<SupportedMetadataTypes, Mdf | undefined>;
  }, [mdfs]);

  return { mdfs, mdfsSeparated, mdfsByMType, subTypesByValue, error, loading, refetch };
};
