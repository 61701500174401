import { ElementType } from 'react';

import { ReactComponent as AudioIcon } from 'assets/icons/systemicons/audio.svg';
import { ReactComponent as DocIcon } from 'assets/icons/systemicons/Doc.svg';
import { ReactComponent as PdfIcon } from 'assets/icons/systemicons/PDF.svg';
import { ReactComponent as PptIcon } from 'assets/icons/systemicons/PPT.svg';
import { ReactComponent as SoundtrackIcon } from 'assets/icons/systemicons/soundtrack.svg';
import { ReactComponent as TextOffIcon } from 'assets/icons/systemicons/text_off.svg';
import defaultThumbnail from 'assets/images/default/defaultThumbnail.png';
import FallbackSrc from 'assets/images/defaultStoryCoverPhoto.jpg';
import { Asset } from 'types';
import getExtensionFromUrl from 'utils/getExtensionFromUrl';
import { extractObjectKeyValue } from 'utils/objects';

const IconImage = {
  '.pdf': PdfIcon,
  '.ppt': PptIcon,
  '.pptx': PptIcon,
  '.doc': DocIcon,
  '.docx': DocIcon,
  '.xlsx': TextOffIcon,
  '.xls': TextOffIcon,
  '.txt': TextOffIcon,
  '.csv': TextOffIcon,
  '.mp3': SoundtrackIcon,
  defaultDoc: TextOffIcon,
  defaultAudio: AudioIcon,
};

// eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
const getAssetThumbnail = (asset: Asset): ElementType | string => {
  const { itemType = '', mThumbnailUrl, mTitle, mContentUrl, mMetaData, mediaType = '' } = asset;

  // return default thumbnail for audio
  if (['audio'].includes(itemType)) return IconImage.defaultAudio;

  // if not image/video/audio
  if (!['image', 'video', 'audio'].includes(itemType)) {
    const isPdf = mediaType?.includes('pdf') || mTitle?.toLowerCase().includes('.pdf');

    const IconComponent =
      IconImage[
        (isPdf && '.pdf') ||
          (extractObjectKeyValue(mMetaData, 'extension') as keyof typeof IconImage) ||
          (getExtensionFromUrl(mContentUrl) as keyof typeof IconImage)
      ];

    return IconComponent;
  }

  // if a placeholder return thumbnail or default img
  // else return thumbnail or content or fallback img
  return mediaType.includes('placeholder')
    ? mThumbnailUrl || defaultThumbnail
    : mThumbnailUrl || mContentUrl || FallbackSrc;
};

export default getAssetThumbnail;
