import gql from 'graphql-tag';

export default gql`
  mutation updateInstance($input: UpdateInstanceInput) {
    updateInstance(input: $input) {
      mId
      mRefId
      mTitle
      mStoryId
      mContentKey
      mThumbnailKey
      mDefaultContentKey
      mDescription
      mType
      mPublishingAt
      mUpdatedAt
      mUpdatedById
      mState
      locked
      mAssignedMembers {
        mId
        mType
      }
      items {
        title
        itemId
        templateType
        templateVariant
        iconUrl
        subItems {
          itemId
          templateType
          templateVariant
          iconUrl
        }
      }
      mMetaData {
        key
        value
        manual
        autoValue
      }
      mRelatedMembers
      mArchivingDisabled
      mProperties {
        __typename
        ... on PlatformType {
          provider {
            id
            message
          }
          platform
          platformKind
          id
          account {
            accountUrl
            accountLogo
            accountTitle
            accountId
            accountRefId
            id
            orderType
          }
        }
      }
    }
  }
`;
