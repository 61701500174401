import { useCallback } from 'react';

import useDateTimeUtils, { toTimeZoneDate } from 'hooks/useDateTimeUtils';
import useTimezoneSettings from 'hooks/useTimezoneSettings';

import useCustomDateTimeUtils from './useCustomDateTimeUtils';

type DateTimeType = Date | string | number | null;

const useSanitizeDates = () => {
  const timezone = useTimezoneSettings();
  const { startOfDay, endOfDay } = useDateTimeUtils();
  const { parseISOWithOffsetToUTC } = useCustomDateTimeUtils();

  const sanitizeDates = useCallback(
    (startDate?: DateTimeType, endDate?: DateTimeType): { from: string; to: string } => {
      const zonedDate = toTimeZoneDate(timezone)(new Date());
      const startTime = startDate ?? endDate ?? zonedDate;
      const endTime = endDate ?? startDate ?? zonedDate;

      const sanitizedStartDate = startOfDay(startTime).toISOString();
      const sanitizedEndDate = endOfDay(endTime).toISOString();

      return {
        from: parseISOWithOffsetToUTC(sanitizedStartDate),
        to: parseISOWithOffsetToUTC(sanitizedEndDate),
      };
    },
    [],
  );

  return { sanitizeDates };
};

export default useSanitizeDates;
