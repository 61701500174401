import { useCallback } from 'react';
import { tzOffset } from '@date-fns/tz';
import { utc } from '@date-fns/utc';

import getLocaleTimeFormat from 'utils/dateTime/getLocaleTimeFormat';

import useDateTimeUtils, { DateType } from './useDateTimeUtils';
import useTimezoneSettings from './useTimezoneSettings';

interface Session {
  mState: string;
  mProperties?: {
    sessionExpirationTime?: string;
  };
}
interface DateRange {
  startDate: string;
  endDate: string;
}

export type PublishedDateOptions = {
  anotherYear?: string;
  formatString?: string;
  today?: string;
  tomorrow?: string;
  yesterday?: string;
};

export type RelativeDateTimeOptions = {
  fillerString?: string;
  showRelative?: boolean;
  dateFormat?: string;
  timeFormat?: string;
};

export const defaultPublishedDateOptions = {
  anotherYear: 'E d MMM yy',
  formatString: 'd MMM, HH:mm',
  today: "'Today', HH:mm",
  tomorrow: "'Tomorrow', HH:mm",
  yesterday: "'Yesterday', HH:mm",
} as const;

const dateStringAbbreviation = {
  year: 'yr',
  month: 'mo',
  hour: 'hr',
  minute: 'min',
  second: 'sec',
};

const DATE_STRING_REGEX = /year|month|hour|minute|second/gi;

const useCustomDateTimeUtils = () => {
  const timezone = useTimezoneSettings();
  const {
    isBefore,
    startOfDay,
    isToday,
    isTomorrow,
    isThisYear,
    isYesterday,
    isThisWeek,
    getMinutes,
    startOfMinute,
    format,
    isSameDay,
    setHours,
    setMinutes,
    setSeconds,
    differenceInMinutes,
    formatDistanceStrict,
    isAfter,
    differenceInCalendarYears,
    differenceInCalendarDays,
    parseISO,
  } = useDateTimeUtils();

  /**
   * Calculates the GMT offset string for a given date based on the specified timezone.
   *
   * @param date - The date for which to calculate the GMT offset.
   * @returns A string representing the GMT offset in the format `(GMT±HH:MM)`.
   */
  const getGMTOffset = useCallback(
    (date: Date) => {
      const timezoneOffset = tzOffset(timezone, date) / 60;
      const sign = timezoneOffset >= 0 ? '+' : '-';
      const absoluteOffset = Math.abs(timezoneOffset);
      const hours = Math.floor(absoluteOffset);
      const minutes = (absoluteOffset % 1) * 60;
      return `(GMT${sign}${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')})`;
    },
    [timezone],
  );

  /**
   * Given a date in ISO format, and Updated Time returns updated ISO string with updated time
   *
   * @returns string representation of ISO updated date-time
   */
  const updateTimeOfISODate = useCallback((previousDate: Date, updatedTime: string) => {
    const [hour, minute, second] = updatedTime.split(':');
    let time = previousDate;
    time = setHours(time, parseInt(hour));
    time = setMinutes(time, parseInt(minute));
    if (second) time = setSeconds(time, parseInt(second));
    else time = setSeconds(time, 0);

    return time.toISOString();
  }, []);

  const isBeforeToday = useCallback(
    (date: string | number | Date) => isBefore(startOfDay(date), startOfDay(new Date())),
    [],
  );

  /**
   * Adds relative context to the date (today, tomorrow, or yesterday) for the user.
   * If the date is none of these shows the date in a user given format
   */
  const getRelativeDate = useCallback(
    (date: DateType, dateFormat: string = 'E. d MMMM yyyy', showTime = false) => {
      const localeTimeFormat = getLocaleTimeFormat();
      if (isToday(date)) return showTime ? `Today at ${format(date, localeTimeFormat)}` : 'Today';
      if (isTomorrow(date))
        return showTime ? `Tomorrow at ${format(date, localeTimeFormat)}` : 'Tomorrow';
      if (isYesterday(date))
        return showTime ? `Yesterday at ${format(date, localeTimeFormat)}` : 'Yesterday';
      return format(date, dateFormat);
    },
    [],
  );

  const getRelativeDateTime = useCallback(
    (
      date: DateType,
      {
        timeFormat = getLocaleTimeFormat(),
        dateFormat = 'd MMM. yyyy HH:mm',
        fillerString = ' at ',
        showRelative = true,
      }: RelativeDateTimeOptions,
    ) => {
      const timeString = format(date, timeFormat);
      if (!showRelative) return timeString;

      if (isToday(date)) return `Today${fillerString}${timeString}`;

      if (isTomorrow(date)) return `Tomorrow${fillerString}${timeString}`;

      if (isYesterday(date)) return `Yesterday${fillerString}${timeString}`;

      return `${format(date, dateFormat)}`;
    },
    [],
  );

  const getRelativeDateTimeRange = useCallback((date1: DateType, date2: DateType) => {
    const sameDay = isSameDay(date1, date2);

    const firstDatePart = getRelativeDateTime(date1, {
      fillerString: sameDay ? ',\n' : ',',
      showRelative: true,
    });
    const secondDatePart = getRelativeDateTime(date2, {
      fillerString: sameDay ? '' : ',',
      showRelative: !sameDay,
    });

    return `${firstDatePart}-${secondDatePart}`;
  }, []);

  const getDisplayStringForDateRange = useCallback((range: DateRange) => {
    if (!range) return 'No date selected';
    const { startDate, endDate } = range;
    if (isSameDay(startDate, endDate)) return getRelativeDate(endDate);
    return `${getRelativeDate(startDate)} - ${getRelativeDate(endDate)}`;
  }, []);

  /**
   * Formats a date into a human-readable relative time string
   * @returns Formatted string like "Just Now", "5 min. ago", "Today 14:30", etc.
   */
  const formatRelativeTime = useCallback((date: DateType, lineBreak = true): string => {
    const diff = differenceInMinutes(new Date(), date);
    if (diff < 5) {
      return `Just${lineBreak ? ' ' : '\n'}Now`;
    }
    if (diff >= 5 && diff < 45) {
      return `${diff} min.\nago`;
    }
    if (diff >= 45 && diff < 75) {
      return '1 hr.\nago';
    }
    if (isToday(date)) {
      return `Today\n${format(date, 'HH:mm')}`;
    }
    if (isYesterday(date)) {
      return `Yesterday\n${format(date, 'HH:mm')}`;
    }
    return `${format(date, 'dd MMM')}\n${format(date, 'HH:mm')}`;
  }, []);

  /**
   * Rounds the given date to the nearest minute (or number of minutes).
   * Rounds up when the given date is exactly between the nearest round minutes.
   */
  const roundToNearestMinutes = useCallback((date: DateType, options: { nearestTo: number }) => {
    const { nearestTo } = options;
    const roundedMinutes = Math.ceil(getMinutes(date) / nearestTo) * nearestTo;
    return setMinutes(startOfMinute(date), roundedMinutes);
  }, []);

  /**
   * Take an ISO string and return a boolean indicating if the date is at least one year before.
   */
  const isAtLeastOneYearBefore = (dateToCheck?: DateType) => {
    if (!dateToCheck) return false;

    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);

    return new Date(dateToCheck).getTime() <= oneYearAgo.getTime();
  };

  const distanceInAbbreviatedWords = useCallback(
    (input: DateType) =>
      formatDistanceStrict(input, new Date()).replace(
        DATE_STRING_REGEX,
        (matched) => dateStringAbbreviation[matched as keyof typeof dateStringAbbreviation],
      ),
    [],
  );

  const distanceInWords = useCallback((oldDate?: DateType) => {
    if (!oldDate) return '';
    const timeStr = formatDistanceStrict(oldDate, new Date());
    if (timeStr.includes('second')) return 'Just now';
    return timeStr;
  }, []);

  const isValidSession = useCallback((session: Session | null): boolean => {
    if (!session) return false;
    const { mState } = session;

    if (mState !== 'active') return false;

    const sessionExpirationTime = session.mProperties?.sessionExpirationTime;

    if (!sessionExpirationTime) return false;

    const currentTime = new Date();
    return isAfter(new Date(sessionExpirationTime), currentTime);
  }, []);

  const isoToLocaleVeryShort = useCallback((date?: DateType | null): string => {
    if (!date) return '';
    const localeTimeFormat = getLocaleTimeFormat();
    if (isToday(date)) return format(date, localeTimeFormat);
    if (isYesterday(date)) return 'Yesterday';
    if (isTomorrow(date)) return 'Tomorrow';
    if (isThisYear(date)) return format(date, 'dd MMM');
    return format(date, 'dd MMM yy');
  }, []);

  const isoToLocaleShort = useCallback((date?: DateType | null, year: boolean = false): string => {
    if (!date) return '';
    const localeTimeFormat = getLocaleTimeFormat();
    if (isToday(date)) return format(date, localeTimeFormat);
    if (isYesterday(date)) return `Yesterday, ${format(date, localeTimeFormat)}`;
    const dateFormat = year ? `MMM dd, yyyy ${localeTimeFormat}` : `MMM dd ${localeTimeFormat}`;
    return format(date, dateFormat);
  }, []);

  const formatPublishedDate = useCallback(
    (publishedDate: DateType, options: PublishedDateOptions = defaultPublishedDateOptions) => {
      const { anotherYear, today, tomorrow, yesterday, formatString } =
        options ?? defaultPublishedDateOptions;
      if (differenceInCalendarYears(new Date(), publishedDate) > 0 && anotherYear)
        return `${format(publishedDate, anotherYear)}`;

      if (isToday(new Date(publishedDate)) && today) return `${format(publishedDate, today)}`;

      if (isTomorrow(new Date(publishedDate)) && tomorrow)
        return `${format(publishedDate, tomorrow)}`;

      if (isYesterday(new Date(publishedDate)) && yesterday)
        return `${format(publishedDate, yesterday)}`;

      return formatString ? `${format(publishedDate, formatString)}` : '';
    },
    [],
  );

  const differenceInDays = useCallback((date: DateType) => {
    const value = differenceInCalendarDays(new Date(), date);
    if (value === 0) return '';
    if (value === -1) return ' (in 1 day)';
    if (value < -1) return ` (in ${-value} days)`;
    if (value === 1) return ' (1 day ago)';
    return ` (${value} days ago)`;
  }, []);

  const formatNearbyDate = useCallback((date?: DateType, shouldReturnToday = false) => {
    if (!date) return;
    const parsedDate = new Date(date);
    if (!shouldReturnToday && isToday(parsedDate)) return '';
    if (isThisWeek(parsedDate)) return format(parsedDate, 'EEEE');
    return format(parsedDate, 'E, MMM dd');
  }, []);

  const parseISOWithOffsetToUTC = useCallback(
    (date: string) => parseISO(date, { in: utc }).toISOString(),
    [],
  );

  return {
    isBeforeToday,
    getRelativeDate,
    getRelativeDateTime,
    getRelativeDateTimeRange,
    getDisplayStringForDateRange,
    updateTimeOfISODate,
    formatRelativeTime,
    roundToNearestMinutes,
    isAtLeastOneYearBefore,
    distanceInAbbreviatedWords,
    distanceInWords,
    isValidSession,
    isoToLocaleShort,
    isoToLocaleVeryShort,
    formatPublishedDate,
    differenceInDays,
    getGMTOffset,
    formatNearbyDate,
    parseISOWithOffsetToUTC,
  };
};

export default useCustomDateTimeUtils;

// - DD → dd (01, 02, 03)
// - MMM. → MMM. (Jan. Feb.)
// - YYYY → yyyy (1995, 2024)
// - D → d (1, 2, …, 31)
// - ddd → E (Sun, Mon)
// - HH →  HH (00, 01, …,23)
// - mm → mm (00, 01, …, 59) Minute
// - A → a (AM, PM)
// - dddd → EEEE
// - dd → EEEEEE
// - MM → MM(01, 02, …, 12)
// - MMMM → MMMM
// - ss → ss (Second)
// - SSS → SSS
// - Z → XXX
// - W → w(1, 2, …, 53)
// - hh → hh (01, …,12)
// - a -> aaa
// - h -> h
// - Z -> h
// - YY -> yy
// - [T] -> 'T'
