import { v4 } from 'uuid';

const SESSION_ID_KEY = 'dinaSessionId';

function buildVolatileSessionId(uuid: string) {
  return `volatile-${uuid}`;
}

/**
 * Check if a session ID is volatile (i.e. not persistent)
 * @param sessionId The session ID to check (typically `DINA_SESSION_ID`)
 */
export function isVolatileSessionId(sessionId: string) {
  return sessionId.startsWith('volatile-');
}

/**
 * Try to get a session ID from sessionStorage. If there is none, create a new one and store
 * it in the session storage.
 * If the session storage is unavailable, a volatile session ID will be returned.
 * @returns The session ID
 */
function getSessionId() {
  try {
    const storedSessionId = sessionStorage.getItem(SESSION_ID_KEY);
    if (storedSessionId) return storedSessionId;
    const newSessionId = v4();
    sessionStorage.setItem(SESSION_ID_KEY, newSessionId);
    return newSessionId;
  } catch {
    return buildVolatileSessionId(v4());
  }
}

export const DINA_SESSION_ID = getSessionId();
