import { atom, useAtom, useAtomValue, useSetAtom } from 'jotai';
import { createScope, molecule, useMolecule } from 'jotai-molecules';

import useSettingsValue from 'hooks/useSettingsValue';
import { policiesAtom, userConfigAtom } from 'store';
import { mimirProviderAtomForStory } from 'store/assetProvider';
import { storyTabs } from 'store/tabs';
import { Platform, Story } from 'types';
import checkUserRight from 'utils/checkUserRight';
import { UNTITLED_STORY } from 'utils/constants';
import getEmptyMetadataForForm from 'utils/getEmptyMetadata';
import getSettingsProperty from 'utils/getSettingsProperty';
import getHostReadSpeed from 'utils/instance/getHostReadSpeed';
import { pitchTypes } from 'utils/pitch';
import syncSyncProviders from 'utils/syncSyncProviders';

export const initialTabs = Object.values(storyTabs).map((value) => ({ label: value }));

export const StoryScope = createScope<string | undefined>(undefined);

export const storyMolecule = molecule((_getMol, getScope) => {
  const scope = getScope(StoryScope);
  const [, preview] = (scope ?? '').split(':');
  const queryTagAtom = atom(preview === 'preview' ? 'previewPanes' : 'storyPanes');

  const storyAtom = atom<Story | undefined>(undefined);
  const platformsAtom = atom<Platform[]>([]);
  const [getSettingsValue] = useSettingsValue();

  const isBookingEnabledAtom = atom((get) => {
    const policies = get(policiesAtom);
    const story = get(storyAtom);
    const isPitch = pitchTypes.has(story?.mType ?? '');

    const groupPolicyScheduleAllPermission = checkUserRight(policies, 'scheduleEntry', 'access');
    const settingsScheduleAllPermission = getSettingsValue('scheduler.enable') === 'true';

    const canSeeScheduleAll = settingsScheduleAllPermission && groupPolicyScheduleAllPermission;

    return canSeeScheduleAll && !isPitch;
  });

  const storyTabsAtom = atom((get) => {
    const policies = get(policiesAtom);

    const story = get(storyAtom);
    if (!story) return [];

    const isBookingEnabled = get(isBookingEnabledAtom);
    const instancesEnabled = checkUserRight(policies, 'story', 'access-instances');
    const notesEnabled = checkUserRight(policies, 'story', 'access-notes');
    const locationsEnabled = checkUserRight(policies, 'story', 'access-locations');
    const assetsEnabled = checkUserRight(policies, 'story', 'access-assets');
    const resourcesEnabled = checkUserRight(policies, 'story', 'access-resources');
    const metadataEnabled = checkUserRight(policies, 'story', 'access-metadata');
    const tasksEnabled = checkUserRight(policies, 'story', 'access-tasks');
    const planningEnabled = checkUserRight(policies, 'story', 'access-planning');
    const canAccessSpaces = checkUserRight(policies, 'space', 'access');
    const canAccessOrderManagement = checkUserRight(policies, 'task', 'access') && canAccessSpaces;

    const tabs = pitchTypes.has(story.mType)
      ? initialTabs.filter((tab) => {
          switch (tab.label) {
            case storyTabs.notes:
              return !!notesEnabled;
            case storyTabs.content:
              return true;
            case storyTabs.assets:
              return !!assetsEnabled;
            case storyTabs.locations:
              return !!locationsEnabled;
            case storyTabs.resources:
              return !!resourcesEnabled;
            case storyTabs.metadata:
              return !!metadataEnabled;
            default:
              return false;
          }
        })
      : initialTabs.filter((tab) => {
          const label = tab.label;

          switch (label) {
            case storyTabs.instances:
              return !!instancesEnabled;
            case storyTabs.notes:
              return !!notesEnabled;
            case storyTabs.locations:
              return !!locationsEnabled;
            case storyTabs.assets:
              return !!assetsEnabled;
            case storyTabs.resources:
              return !!resourcesEnabled;
            case storyTabs.metadata:
              return !!metadataEnabled;
            case storyTabs.blocks:
              return !!planningEnabled;
            case storyTabs.tasks:
              return !!canAccessOrderManagement && !!tasksEnabled;
            case storyTabs.bookings:
              return isBookingEnabled;
            default:
              return true;
          }
        });

    return tabs;
  });

  const storyHeaderTitle = atom((get) => {
    const storyTitle = get(storyAtom)?.mTitle;
    return storyTitle === UNTITLED_STORY ? '' : storyTitle;
  });

  const hostReadSpeedAtom = atom((get) => {
    const userConfig = get(userConfigAtom);
    const story = get(storyAtom);

    const { mMetaData } = story ?? {};
    const { metadataForms = [], settings = [] } = userConfig;

    const defaultReadSpeed = getSettingsProperty(
      settings[0] || {},
      'rundown.defaultReadSpeed',
    ) as number;
    const blankMetaData = getEmptyMetadataForForm(metadataForms[0]);
    const hostReadSpeed = getHostReadSpeed(mMetaData ?? blankMetaData, defaultReadSpeed);

    return hostReadSpeed;
  });

  const isStoryArchivedAtom = atom((get) => {
    const story = get(storyAtom);
    return story?.mType?.startsWith('archived_');
  });

  const canUpdateStoryAtom = atom((get) => {
    const story = get(storyAtom);
    const policies = get(policiesAtom);
    const isStoryArchived = get(isStoryArchivedAtom);

    if (story?.__typename === 'RestrictedErrorType') return false;

    if (isStoryArchived) return false;

    const isPitch = pitchTypes.has(story?.mType ?? '');

    const canUpdateStory = checkUserRight(policies, 'story', 'update');
    const canUpdatePitch = checkUserRight(policies, 'pitch', 'update');

    return isPitch ? canUpdatePitch : canUpdateStory;
  });

  const canUploadMediaAtom = atom((get) => {
    const policies = get(policiesAtom);

    return checkUserRight(policies, 'feature', 'upload-media');
  });

  const isPitchAtom = atom((get) => {
    const story = get(storyAtom);

    return pitchTypes.has(story?.mType ?? '');
  });

  const isDescriptionHiddenSettingsAtom = atom((get) => {
    const policies = get(policiesAtom);
    const isPitch = get(isPitchAtom);
    const isStoryDescriptionHidden = checkUserRight(policies, 'story', 'hide-description');
    const isPitchDescriptionHidden = checkUserRight(policies, 'pitch', 'hide-description');
    return isPitch ? isPitchDescriptionHidden : isStoryDescriptionHidden;
  });

  const canCreateInstancesSettingsAtom = atom((get) => {
    const policies = get(policiesAtom);
    const canCreateInstances = checkUserRight(policies, 'instance', 'create');
    return canCreateInstances;
  });

  const canAccessNotesSettingsAtom = atom((get) => {
    const policies = get(policiesAtom);
    const notesEnabled = checkUserRight(policies, 'story', 'access-notes');
    return notesEnabled;
  });

  const contentUrlAtom = atom((get) => {
    const story = get(storyAtom);
    return story?.mContentUrl;
  });

  const storyIdAtom = atom((get) => {
    const story = get(storyAtom);
    return story?.mId;
  });

  const updateSyncProviderAtom = atom(null, (get, set) => {
    const syncProviders = get(mimirProviderAtomForStory);
    const story = get(storyAtom);

    const mimirProvider = story?.mSyncProviders?.find((sp) => sp.provider === 'Mimir');
    const newFolder = mimirProvider?.mMetaData?.find((mdata) => mdata.key === 'folder');
    if (!newFolder) return;

    const currentMimirProvider = syncProviders?.find((sp) => sp.provider === 'Mimir');
    const currentFolder = currentMimirProvider?.mMetaData?.find((mdata) => mdata.key === 'folder');
    if (currentFolder !== newFolder) {
      set(mimirProviderAtomForStory, story?.mSyncProviders ?? null);
      syncSyncProviders(story?.mSyncProviders);
    }
  });

  return {
    isWithinStoryScope: !!scope,
    isPreview: preview === 'preview',
    scopeId: scope,
    useStory: () => useAtom(storyAtom),
    useStoryIdValue: () => useAtomValue(storyIdAtom),
    usePlatforms: () => useAtom(platformsAtom),
    useStoryTabsValue: () => useAtomValue(storyTabsAtom),
    useStoryHeaderTitle: () => useAtom(storyHeaderTitle),
    useHostReadSpeedValue: () => useAtomValue(hostReadSpeedAtom),
    useCanUpdateStoryValue: () => useAtomValue(canUpdateStoryAtom),
    useCanUploadMediaValue: () => useAtomValue(canUploadMediaAtom),
    useContentUrlValue: () => useAtomValue(contentUrlAtom),
    useIsStoryArchivedValue: () => useAtomValue(isStoryArchivedAtom),
    useIsPitchValue: () => useAtomValue(isPitchAtom),
    useSetSyncProvider: () => useSetAtom(updateSyncProviderAtom),
    useQueryTagValue: () => useAtomValue(queryTagAtom),
    useIsDescriptionHiddenSettingsValue: () => useAtomValue(isDescriptionHiddenSettingsAtom),
    useCanCreateInstances: () => useAtomValue(canCreateInstancesSettingsAtom),
    useCanAccessNotes: () => useAtomValue(canAccessNotesSettingsAtom),
  };
});

export const useStoryMolecule = () => useMolecule(storyMolecule);
