import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { v4 as uuid } from 'uuid';

import { getErrorMessage } from 'api/commonKeys';
import { SpaceViewLayout } from 'features/layouts/types';
import memberTypes from 'operations/memberTypes/memberTypes';
import { getMembersQuery } from 'operations/queryVariables';
import { useActiveSpaceView } from 'screens/space/store/space';
import { MemberType } from 'types/graphqlTypes';
import useLogger from 'utils/useLogger';

import { CREATE_VIEW, GET_VIEWS } from './graphql';

interface CreateView {
  createMember: MemberType;
}

interface CreateInput {
  spaceId: string;
  mTitle: string;
  layout: SpaceViewLayout;
}

interface CachedMembers {
  getMembers: MemberType[];
}

/** Creates a new view within the current space */
export default function useCreateView() {
  const [createMutation] = useMutation<CreateView>(CREATE_VIEW);
  const [, setActiveTab] = useActiveSpaceView();
  const logger = useLogger('createSpaceView');

  const create = useCallback(
    async ({ spaceId, mTitle, layout }: CreateInput) => {
      try {
        const res = await createMutation({
          variables: {
            input: {
              mId: spaceId,
              mRefId: uuid(),
              mTitle: mTitle ?? 'Untitled View',
              mType: memberTypes.SPACE_VIEW,
              configs: [{ key: 'layout', value: layout }],
            },
          },
          update(proxy, mutationResult) {
            if (mutationResult.data) {
              const { createMember } = mutationResult.data;
              const cachedViews = proxy.readQuery<CachedMembers>({
                query: GET_VIEWS,
                variables: getMembersQuery(spaceId, memberTypes.SPACE_VIEW),
              });

              const allViews = [...(cachedViews?.getMembers ?? []), createMember];

              proxy.writeQuery({
                query: GET_VIEWS,
                variables: getMembersQuery(spaceId, memberTypes.SPACE_VIEW),
                data: { getMembers: allViews },
              });
            }
          },
        });

        const createdMemberId = res?.data?.createMember?.mRefId;
        setActiveTab(createdMemberId);
      } catch (err) {
        logger.log(getErrorMessage(err, { spaceId, mTitle }));
      }
    },
    [createMutation],
  );

  return { create };
}
