import { useCallback } from 'react';
import { TZDate } from '@date-fns/tz';

import useDateTimeUtils, { DateType } from './useDateTimeUtils';
import useTimezoneSettings from './useTimezoneSettings';

const toValidString = (str?: string) => str ?? '0';

/**
 * Custom hook to get rundown timings.
 */
const useGetRundownTimings = () => {
  const appTimezone = useTimezoneSettings();
  const { getYear, getMonth, getDate } = useDateTimeUtils();

  /**
   * Converts a date in a specific timezone to a UTC date string.
   * @param date - The date to convert.
   * @param rundownTimezone - The timezone of the date.
   * @returns The UTC date string.
   */
  const getUTCDateFromSourceDate = useCallback((date: Date, rundownTimezone: string) => {
    const sourceDateTime = new Date(
      date.toLocaleString('en-US', {
        timeZone: rundownTimezone,
      }),
    );
    const offset = date.getTime() - sourceDateTime.getTime();
    const localDateTime = new Date(date.getTime() + offset);
    return localDateTime.toISOString();
  }, []);

  /**
   * Gets the rundown publishing time in UTC.
   * @param date - The selected date of the rundown.
   * @param startTime - The start time of the rundown in HH:mm:ss format.
   * @param rundownTimezone - The timezone of the rundown.
   * @returns The UTC date string of the rundown publishing time.
   */
  const getRundownPublishingTime = useCallback(
    (date: DateType, startTime: string, rundownTimezone: string) => {
      const year = getYear(date);
      const month = getMonth(date);
      const day = getDate(date);
      const [hourPart, minutePart, secondPart] = startTime.split(':');
      const hours = parseInt(toValidString(hourPart));
      const minutes = parseInt(toValidString(minutePart));
      const seconds = parseInt(toValidString(secondPart));

      const publishingDate = new TZDate(year, month, day, hours, minutes, seconds, appTimezone);
      return getUTCDateFromSourceDate(publishingDate, rundownTimezone);
    },
    [appTimezone, getUTCDateFromSourceDate],
  );

  return { getRundownPublishingTime };
};

export default useGetRundownTimings;
