import uniqolor from 'uniqolor';

import { User } from 'types';

import { deepFreeze } from './objectUtils';

const COLOR_OPTIONS: Parameters<typeof uniqolor>[1] = deepFreeze({
  saturation: [50, 90],
});

export function getUserColor(user: User): string {
  return uniqolor(user.mId, COLOR_OPTIONS).color;
}
