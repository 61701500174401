import useSettingsValue from './useSettingsValue';

export const getLocalTimezone = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone;
export const getAllTimezones = () =>
  Intl.supportedValuesOf('timeZone').map((timezone) => ({ id: timezone, value: timezone }));

const useTimezoneSettings = () => {
  const [getSettingsValue] = useSettingsValue();

  const timezone = getSettingsValue('app.timezone') as string;
  return timezone ?? getLocalTimezone();
};

export default useTimezoneSettings;
