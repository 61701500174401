import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as Back } from 'assets/icons/systemicons/arrows/disclosurearrow_left.svg';
import { ReactComponent as Close } from 'assets/icons/systemicons/close.svg';
import { ReactComponent as Open } from 'assets/icons/systemicons/open_small.svg';
import { IconButton } from 'components/buttons';
import Scrollbar from 'components/scrollbar';
import Text from 'components/text';
import PreviewChild from 'features/preview/ChildFactory';
import { Box, HStack, VStack } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';
import { MemberType, MemberTypeEnum } from 'types/graphqlTypes';

import Story from './story/Story';

const Container = styled(VStack, transientOptions)<{ $showPreview?: boolean }>`
  z-index: 10;
  ${({ $showPreview }) =>
    $showPreview
      ? css`
          position: absolute;
          inset: 0;
        `
      : css`
          width: 100%;
          height: 100%;
        `}
  background-color: ${({ theme }) => theme.palette.dina.surfaceAppBackgroundNavLevel2};
`;

const Header = styled(HStack, transientOptions)<{ $showPreview?: boolean }>`
  width: 100%;
  height: 40px;
  padding-inline: ${({ $showPreview }) => ($showPreview ? '4px' : '12px 4px')};
  gap: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

const HeaderText = styled(Text)`
  width: calc(100% - 36px);
`;

const renderPreview = (member: MemberType) => {
  switch (member.mType) {
    case MemberTypeEnum.Story:
    case MemberTypeEnum.ResStory:
    case MemberTypeEnum.ArchivedStory:
    case MemberTypeEnum.ArchivedResStory:
    case MemberTypeEnum.Pitch:
    case MemberTypeEnum.ResPitch:
    case MemberTypeEnum.ArchivedPitch:
    case MemberTypeEnum.ArchivedResPitch:
      return <Story story={member} />;
    case MemberTypeEnum.User:
    case MemberTypeEnum.Team:
    case MemberTypeEnum.Department:
      return <PreviewChild member={member} onClose={() => {}} />;
    default:
      break;
  }
};

interface InlinePreviewProps {
  member?: MemberType | null;
  openItem: (val: MemberType) => void;
  showPreview?: boolean;
  onBack?: () => void;
}

function InlinePreview({ member, openItem, showPreview, onBack }: Readonly<InlinePreviewProps>) {
  if (member) {
    return (
      <Container $showPreview={showPreview}>
        <Header $showPreview={showPreview}>
          {showPreview && (
            <IconButton onClick={onBack} usage="text" size={28}>
              <Back />
            </IconButton>
          )}
          <HeaderText variant="h7" color="highEmphasis" truncate>
            {member.mTitle}
          </HeaderText>
          <IconButton usage="text" size={32} onClick={() => openItem(member)}>
            <Open />
          </IconButton>
          <IconButton usage="text" size={32} onClick={onBack}>
            <Close />
          </IconButton>
        </Header>
        <Box flex="1" width="100%">
          <Scrollbar top={5} bottom={5}>
            {renderPreview(member)}
          </Scrollbar>
        </Box>
      </Container>
    );
  }
  return null;
}

export default InlinePreview;
