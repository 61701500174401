import styled from '@emotion/styled';

import { ReactComponent as CommentEmpty } from 'assets/icons/systemicons/commentEmpty.svg';
import { ReactComponent as CommentResolved } from 'assets/icons/systemicons/commentResolved.svg';
import { ReactComponent as CommentWithCounter } from 'assets/icons/systemicons/commentWithCounter.svg';

export { CommentEmpty };

export const CommentResolvedStyled = styled(CommentResolved)`
  path {
    fill: ${({ theme }) => theme.palette.dina.statusApproved} !important;
  }
`;

const Wrapper = styled('div')`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    path {
      fill: ${({ theme }) => theme.palette.dina.mediumEmphasis} !important;
    }
  }
  span {
    position: absolute;
    width: 16px;
    height: 12px;
    top: 5px;
    font-weight: 600;
    font-size: 9px;
    line-height: 10px;
    text-align: center;
    color: ${({ theme }) => theme.palette.dina.mediumEmphasis};
  }

  :hover {
    svg {
      path {
        fill: ${({ theme }) => theme.palette.dina.highEmphasis} !important;
      }
    }
    span {
      color: ${({ theme }) => theme.palette.dina.highEmphasis};
    }
  }
`;

export const CommentWithComments = ({ count }: { count: number }) => {
  const countValue = count > 98 ? '99+' : count + 1;

  return (
    <Wrapper>
      <CommentWithCounter />
      <span>{countValue}</span>
    </Wrapper>
  );
};
