import gql from 'graphql-tag';

export default gql`
  query getStoryInstances($input: GetMembersOfTypeBySecIdInput) {
    getMembersOfTypeBySecId(input: $input) {
      mId
      mRefId
      mTitle
      mType
      mState
      mPublishingAt
      mPublishingEnd
      mUpdatedAt
      mCreatedAt
      mUpdatedById
      mAvatarKey
      mStoryId
      mThumbnailKey
      mContentKey
      mDefaultContentKey
      mDescription
      locked
      isRestricted
      mRundownTemplateId
      metadata
      mProperties {
        __typename
        ... on PlatformType {
          platform
          platformKind
          id
          account {
            id
            accountId
            accountRefId
            accountUrl
            accountLogo
            accountTitle
            orderType
          }
          provider {
            id
            requestId
            state
            message
            embeddedEndpoint
            previewEndpoint
            updateEndpoint
          }
        }
      }
      mMetaData {
        key
        value
        manual
        autoValue
      }
      mAssignedMembers {
        mId
        mType
      }
      items {
        itemId
        title
        templateType
        templateVariant
        iconUrl
        subItems {
          itemId
          title
          templateType
          templateVariant
          iconUrl
        }
      }
      mRelatedMembers
      mArchivingDisabled
    }
  }
`;
