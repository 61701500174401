import { TZDate } from '@date-fns/tz';
import { Link, Text, View } from '@react-pdf/renderer';
import { format, isToday, isYesterday } from 'date-fns';
import keyBy from 'lodash/keyBy';
import { v4 as uuidV4 } from 'uuid';

import getCleanLink from 'components/editor/utils/getCleanLink';
import { isMiniMemberArray } from 'components/mdfEditor/fields/relation/relation-utils';
import { getSubMdf, hasPermission, shouldShowField } from 'features/mdf/mdf-utils';
import { FieldValue, Metadata } from 'types/forms/forms';
import { FieldTypeEnum, MemberType, MemberTypeEnum } from 'types/graphqlTypes';
import getLocaleTimeFormat from 'utils/dateTime/getLocaleTimeFormat';
import getMembersFromRelationField from 'utils/mdf/getMembersFromRelationField';

import { getMdfFieldComponent } from '../docs/utils';
import { styles } from '../styles';
import {
  MdfFieldProps,
  RenderChoiceFieldProps,
  RenderMdfContactFieldProps,
  RenderMdfRelationFieldProps,
  RenderMdfUserFieldProps,
} from '../types';
import { getContactMetadata } from '../utils/getContactMetadata';

import { Block } from './Block';

const emptyUserMeta = '{"email": "", "phone": ""}';

const formatToLocale = (date?: string | null, timezone?: string, year: boolean = false): string => {
  if (!date) return '';
  const localeTimeFormat = getLocaleTimeFormat();
  const zonedDate = new TZDate(date, timezone);

  if (isToday(zonedDate)) return format(zonedDate, localeTimeFormat);
  if (isYesterday(zonedDate)) return `Yesterday, ${format(zonedDate, localeTimeFormat)}`;
  const dateFormat = year ? `MMM dd, yyyy ${localeTimeFormat}` : `MMM dd ${localeTimeFormat}`;
  return format(zonedDate, dateFormat);
};

export const SingleMetadata = ({ label, value }: { label: string; value: FieldValue }) => {
  const printAbleValue =
    typeof value === 'string' || typeof value === 'number' ? value : JSON.stringify(value);

  return (
    <View style={styles.fieldWrapper}>
      <Text style={styles.fieldLabel}>{label.charAt(0).toUpperCase() + label.slice(1)}</Text>
      <Text style={styles.fieldContent}>{printAbleValue}</Text>
      <View style={styles.horizontalLine} />
    </View>
  );
};

export const MemberField = ({
  member,
  printAllFields,
}: {
  member: MemberType | undefined;
  printAllFields?: boolean;
}) => {
  let memberMeta: Metadata = {};

  try {
    memberMeta = JSON.parse(member?.metadata ?? emptyUserMeta) as Metadata;
  } catch (e) {
    //
  }

  if (printAllFields) {
    return (
      <View style={[styles.fieldWrapper, { border: '1px solid #000', borderRadius: 4 }]}>
        {Object.entries(memberMeta).map(([key, value]) => (
          <SingleMetadata key={key} label={key} value={value} />
        ))}
      </View>
    );
  }

  return (
    <View style={[styles.fieldWrapper, { border: '1px solid #000', borderRadius: 4 }]}>
      <Text style={styles.fieldLabel}>Name</Text>
      <Text style={styles.fieldContent}>
        {member?.mTitle ?? ''} [{member?.mType?.toUpperCase()}]
      </Text>
      <View style={styles.horizontalLine} />
      {memberMeta?.email && (
        <>
          <Text style={styles.fieldLabel}>Email</Text>
          <Text style={styles.fieldContent}>{(memberMeta?.email as string | undefined) ?? ''}</Text>
          <View style={styles.horizontalLine} />
        </>
      )}

      {memberMeta?.phone && (
        <>
          <Text style={styles.fieldLabel}>Phone</Text>
          <Text style={styles.fieldContent}>{(memberMeta?.phone as string | undefined) ?? ''}</Text>
          <View style={styles.horizontalLine} />
        </>
      )}
    </View>
  );
};

export const renderMdfLinkField = ({ key, label, value, showBottomDivider }: MdfFieldProps) => (
  <View key={key} style={styles.fieldWrapper}>
    <Text style={styles.fieldLabel}>{label}</Text>
    <Link src={getCleanLink(value)}>
      <Text style={[styles.fieldContent, { width: 'auto' }]}>{value}</Text>
    </Link>
    {showBottomDivider && <View style={styles.horizontalLine} />}
  </View>
);

export const renderMdfTreeChoiceField = ({
  key,
  label,
  value,
  showBottomDivider,
}: MdfFieldProps) => (
  <View key={key} style={styles.fieldWrapper}>
    <Text style={styles.fieldLabel}>{label}</Text>
    <Text style={[styles.fieldContent, { alignItems: 'center' }]}>
      {value.replace(/,/g, ' -> ')}
    </Text>
    {showBottomDivider && <View style={styles.horizontalLine} />}
  </View>
);

export const renderMdfCheckboxField = ({ key, label, value, showBottomDivider }: MdfFieldProps) => {
  const displayValue = value === 'true' ? `[x] ${label}` : `[ ] ${label}`;

  return (
    <View key={key} style={styles.fieldWrapper}>
      <Text style={styles.fieldLabel}>{label}</Text>
      <Text style={[styles.fieldContent, { alignItems: 'center' }]}>{displayValue}</Text>
      {showBottomDivider && <View style={styles.horizontalLine} />}
    </View>
  );
};

export const renderMdfDateField = ({
  key,
  label,
  value,
  timezone,
  showBottomDivider,
}: MdfFieldProps) => {
  return (
    <View key={key} style={styles.fieldWrapper}>
      <Text style={styles.fieldLabel}>{label}</Text>
      <Text style={styles.fieldContent}>
        {value !== '' ? formatToLocale(value, timezone, true) : ''}
      </Text>
      {showBottomDivider && <View style={styles.horizontalLine} />}
    </View>
  );
};

export const renderMdfChoiceField = ({
  key,
  label,
  value,
  showBottomDivider,
  alternatives,
  optionListId,
  optionLists,
}: RenderChoiceFieldProps) => {
  const optionList = optionListId ? optionLists?.find((list) => list.id === optionListId) : null;
  const alternateLabel =
    optionList?.optionListType === 'choice'
      ? optionList.alternatives.find((alt) => alt.value === value)?.label
      : alternatives?.find((alt) => alt.value === value)?.label;

  return (
    <View key={key} style={styles.fieldWrapper}>
      <Text style={styles.fieldLabel}>{label}</Text>
      <View style={[styles.fieldContent, { flexDirection: 'row' }]}>
        {value !== '' ? (
          <View style={styles.chip}>
            <Text>{alternateLabel ?? value}</Text>
          </View>
        ) : (
          <View />
        )}
      </View>

      {showBottomDivider && <View style={styles.horizontalLine} />}
    </View>
  );
};

export const renderMdfMultipleChoiceField = ({
  key,
  label,
  value,
  showBottomDivider,
  alternatives,
  optionListId,
  optionLists,
}: RenderChoiceFieldProps) => {
  const optionList = optionListId ? optionLists?.find((list) => list.id === optionListId) : null;
  const alternetLabelMap = new Map<string, string>();

  value.split(',').forEach((fValue) => {
    const alternative =
      optionList?.optionListType === 'choice'
        ? optionList.alternatives.find((alt) => alt.value === fValue)
        : alternatives?.find((alt) => alt.value === fValue);

    if (!alternetLabelMap.has(fValue)) {
      alternetLabelMap.set(fValue, alternative?.label ?? fValue);
    }
  });

  return (
    <View key={key} style={styles.fieldWrapper}>
      <Text style={styles.fieldLabel}>{label}</Text>
      <View style={[styles.fieldContent, { flexDirection: 'row', gap: 6 }]}>
        {value.length > 1
          ? value.split(',').map((iValue) => (
              <View key={uuidV4()} style={styles.chip}>
                <Text>{alternetLabelMap.get(iValue) ?? iValue}</Text>
              </View>
            ))
          : null}
      </View>
      {showBottomDivider && <View style={styles.horizontalLine} />}
    </View>
  );
};

export const renderMdfDefaultField = ({ key, label, value, showBottomDivider }: MdfFieldProps) => (
  <View key={key} style={styles.fieldWrapper}>
    <Text style={styles.fieldLabel}>{label}</Text>
    <Text style={styles.fieldContent}>{value}</Text>
    {showBottomDivider && <View style={styles.horizontalLine} />}
  </View>
);

export const renderMdfContactField = ({
  member,
  contactSettingsMap,
  contactMdf,
  groups,
  subMdfs,
  contacts,
  relationMembers,
  mdfsSeparated,
  contactFieldsToPrint,
  getMember,
  getMemberTitle,
  optionLists,
}: RenderMdfContactFieldProps) => {
  const memberMeta = JSON.parse(member.metadata ?? emptyUserMeta) as Metadata;

  // Remove fields from subMdfs that are not in the contactFieldsToPrint array
  const subMdfsFiltered = contactFieldsToPrint.includes('all')
    ? subMdfs
    : subMdfs?.map((subMdf) => ({
        ...subMdf,
        fields: subMdf.fields.filter((f) => contactFieldsToPrint.includes(f.fieldId)),
      }));

  const subTypes = keyBy(subMdfsFiltered, (mdf) => mdf.label);
  const contactFieldsToPrintArray = new Set(
    contactFieldsToPrint.split(',').map((field) => field.trim()),
  );
  const shouldShowAllFields = contactFieldsToPrint === 'all';

  const visibleFields = contactMdf.fields?.filter((f) =>
    shouldShowField(
      f,
      contactSettingsMap,
      contactSettingsMap,
      false,
      hasPermission(contactMdf?.permissions?.read[f.fieldId], groups),
    ),
  );

  return (
    <View
      key={uuidV4()}
      style={[styles.fieldWrapper, { border: '1px solid #000', borderRadius: 4 }]}
    >
      {visibleFields?.map((field) => {
        if (!shouldShowAllFields && !contactFieldsToPrintArray.has(field.fieldId)) return;

        const fieldValue = memberMeta[field.fieldId];
        const fieldLabel = contactSettingsMap[field.fieldId].label;

        if (field.type === FieldTypeEnum.subtype) {
          const subMdf = getSubMdf(field, memberMeta, subTypes);

          return (
            <View key={field.fieldId} style={{ margin: '8px' }}>
              <Block
                fields={subMdf?.fields}
                layoutSettings={subMdf?.views.default}
                permissions={subMdf?.permissions}
                metadata={memberMeta}
                blockTitle={fieldValue?.toString() ?? contactSettingsMap[field.fieldId].label}
                relationMembers={relationMembers}
                contacts={contacts}
                mdfsSeparated={mdfsSeparated}
                subMdfs={subMdfsFiltered}
                groups={groups}
                optionLists={optionLists}
              />
            </View>
          );
        }

        // Do not render field if it has no value
        if (!fieldValue) return null;

        if ([FieldTypeEnum.text, FieldTypeEnum.number].includes(field.type)) {
          return <SingleMetadata key={field.fieldId} label={fieldLabel} value={fieldValue} />;
        }

        return getMdfFieldComponent({
          field,
          metadata: memberMeta,
          showBottomDivider: true,
          getMemberTitle,
          getMember,
          relationMembers,
          contacts,
          contactFieldsToPrint,
          subMdfs: subMdfsFiltered,
          groups,
          mdfsSeparated,
          settingsMap: contactSettingsMap,
          optionLists,
        });
      })}
    </View>
  );
};

export const renderMdfUserField = ({
  label,
  value,
  showBottomDivider,
  getMember,
  getMemberTitle,
  contacts,
  mdfsSeparated,
  field,
  subMdfs,
  groups,
  relationMembers,
  contactFieldsToPrint,
  optionLists,
}: RenderMdfUserFieldProps) => {
  const title = getMemberTitle(value);
  const user = getMember(value) ?? contacts?.find((contact) => contact.mId === value);

  if (!!user && user.mType === MemberTypeEnum.Contact) {
    const member = getContactMetadata(contacts ?? [], user.mId ?? '');
    const contactMdf = mdfsSeparated.defaults.find((mdf) =>
      mdf.id.toLowerCase().includes('contact'),
    );

    if (contactMdf && member) {
      const contactSettingsMap = keyBy(contactMdf.views.default, (setting) => setting.fieldId);

      return (
        <View key={field.fieldId} style={[styles.fieldWrapper]}>
          <Text style={styles.fieldLabel}>{label}</Text>
          <View style={[styles.fieldContent]}>
            {renderMdfContactField({
              member,
              contactSettingsMap,
              groups,
              contactMdf,
              subMdfs,
              mdfsSeparated,
              relationMembers,
              contacts,
              contactFieldsToPrint,
              getMember,
              getMemberTitle,
              optionLists,
            })}
          </View>
        </View>
      );
    }

    return (
      <View key={field.fieldId} style={[styles.fieldWrapper]}>
        <Text style={styles.fieldLabel}>{label}</Text>
        <View style={[styles.fieldContent]} />
      </View>
    );
  }

  return (
    <View key={field.fieldId} style={styles.fieldWrapper}>
      <Text style={styles.fieldLabel}>{label}</Text>
      {user && <Text style={styles.fieldContent}>{user.mTitle}</Text>}
      {!user && title && <Text style={styles.fieldContent}>{title}</Text>}
      {showBottomDivider && <View style={styles.horizontalLine} />}
    </View>
  );
};

export const renderMdfRelationField = ({
  label,
  showBottomDivider,
  field,
  getMember,
  getMemberTitle,
  relationMembers,
  contacts,
  mdfsSeparated,
  subMdfs,
  groups,
  metadata,
  contactFieldsToPrint,
  optionLists,
}: RenderMdfRelationFieldProps) => {
  const actualFieldValue = metadata[field.fieldId];

  const hasContactField = !isMiniMemberArray(actualFieldValue)
    ? false
    : actualFieldValue?.some((f) => f.type === MemberTypeEnum.Contact);

  const listOfRelationalMembers = hasContactField
    ? [...(relationMembers ?? []), ...(contacts ?? [])]
    : [...(relationMembers ?? [])];

  const fieldMembers = getMembersFromRelationField(actualFieldValue, listOfRelationalMembers);

  return (
    <View key={field.fieldId} style={styles.fieldWrapper}>
      <Text style={styles.fieldLabel}>{label}</Text>
      <View
        style={[
          styles.fieldContent,
          { flexDirection: 'column', alignItems: 'flex-start', gap: '4pt' },
        ]}
      >
        {fieldMembers.length !== 0 ? (
          fieldMembers.map((fMember) => {
            if (fMember.mType === MemberTypeEnum.Contact) {
              const member = contacts?.find((contact) => contact.mId === fMember.mId) ?? fMember;

              const contactMdf = mdfsSeparated.defaults.find((mdf) =>
                mdf.id.toLowerCase().includes('contact'),
              );

              if (!contactMdf) return <MemberField key={member?.mRefId} member={member} />;
              if (!member) return;

              const contactSettingsMap = keyBy(
                contactMdf.views.default,
                (setting) => setting.fieldId,
              );

              return renderMdfContactField({
                member,
                contactSettingsMap,
                groups,
                contactMdf,
                subMdfs,
                mdfsSeparated,
                relationMembers,
                contacts,
                contactFieldsToPrint,
                getMember,
                getMemberTitle,
                optionLists,
              });
            }

            if (
              [MemberTypeEnum.User, MemberTypeEnum.Department, MemberTypeEnum.Team].includes(
                fMember?.mType as MemberTypeEnum,
              )
            ) {
              const member = getMember(fMember?.mId as string);

              return <MemberField key={member?.mRefId} member={member} />;
            }

            return (
              <View style={styles.chip} key={uuidV4()}>
                <Text>{`${fMember?.mTitle ?? 'Untitled'} ${
                  fMember?.mType ? `(${fMember?.mType?.toUpperCase()})` : ''
                }`}</Text>
              </View>
            );
          })
        ) : (
          <View />
        )}
      </View>

      {showBottomDivider && <View style={styles.horizontalLine} />}
    </View>
  );
};
