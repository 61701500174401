import { isBefore, isAfter, isEqual, addMinutes, subMinutes } from 'date-fns';
import { toTimeZoneDate } from 'hooks/useDateTimeUtils';
import uuidv1 from 'uuid/v1';

// buffer time for selecting back-to-back resource
const BUFFER_TIME = 1;

/* eslint-disable no-param-reassign */
export const SCHEDULE_RESOURCE_TYPE = {
  project: 'project',
  client: 'client',
  agency: 'agency',
  contact: 'contact',
  request: 'request',
  category: 'category',
  packages: 'packages',
  resource: 'resource',
  status: 'status',
  placeholder: 'placeholder',
};

export const RESOURCE_ALLOCATION_TYPE = {
  BOOKING: 'booking',
  VACATION: 'vacation',
  SICK_DAY: 'sick_day',
  COMP_DAY: 'comp_day',
  SCHEDULED_DAY_OFF: 'scheduled_day_off',
  SHIFT: 'shift',
  OUT_OF_SERVICE: 'out_of_service',
  SCHEDULED_MAINTENANCE: 'scheduled_maintenance',
  OTHER: ' other',
  DAY_IN_LIEU: ' day_in_lieu',
  SABBATICAL: 'sabbatical',
  AVAILABLE_BLOCK: 'available_block',
  OTHER_PAID_ACTIVITY_1: 'other_paid_activity_1',
  OTHER_PAID_ACTIVITY_2: 'other_paid_activity_2',
  OTHER_PAID_ACTIVITY_3: 'other_paid_activity_3',
  OTHER_NON_PAID_ACTIVITY_1: 'other_non_paid_activity_1',
  OTHER_NON_PAID_ACTIVITY_2: 'other_non_paid_activity_2',
  OTHER_NON_PAID_ACTIVITY_3: 'other_non_paid_activity_3',
  COMPANY_HOLIDAY: 'company_holiday',
  UNPAID_SICK_DAY: 'unpaid_sick_day',
};

export const SCHEDULE_RESOURCE_RESOURCE_TYPE = {
  person: 'person',
  equipment: 'equipment',
};

const getMappedData = (unmappedData) => {
  if (!unmappedData) return unmappedData;
  const mappedData = Object.keys(unmappedData).reduce((prev, curr) => {
    if (curr === 'PID') {
      if (unmappedData.mId === 'agency' || unmappedData.mId === 'contact')
        prev.CL_ID = unmappedData[curr];
      else prev.AGENCY_ID = unmappedData[curr];
    } else {
      prev[curr] = unmappedData[curr];
    }
    return prev;
  }, {});
  return mappedData;
};

export const getPreProcessedScheduleResourceData = (scheduleResources) =>
  scheduleResources.map((scheduleResource, index) => {
    const { mMetaData, items, ...rest } = scheduleResource;
    let reducedMetadata = {};
    if (mMetaData) {
      reducedMetadata = mMetaData.reduce((prev, curr) => ({ ...prev, [curr.key]: curr.value }), {});
    }

    const mappedData = getMappedData({
      ...rest,
      ...reducedMetadata,
      items: items ? [...items] : [],
    });

    return mappedData ? { ...mappedData, rowId: index + 1 } : {};
  });

export const filterProjectData = (projectData, clientId, agencyId) =>
  projectData.filter((project) => {
    let showProject = true;
    if (clientId) {
      showProject = showProject && project && project.CL_ID && project.CL_ID === clientId;
    }

    if (agencyId) {
      showProject = showProject && project && project.AGENCY_ID && project.AGENCY_ID === agencyId;
    }

    return showProject;
  });

export const filterAgencyData = (agencyData, project, client) =>
  agencyData.filter((agency) => {
    let showAgency = true;
    if (project && project.AGENCY_ID) {
      showAgency =
        showAgency && project && project.AGENCY_ID && project.AGENCY_ID === agency.mRefId;
    }
    if (client && client.AGENCY_ID) {
      showAgency = showAgency && client && client.AGENCY_ID && client.AGENCY_ID === agency.mRefId;
    }

    return showAgency;
  });

export const filterContactData = (contactData, client) =>
  contactData.filter((contact) => {
    let showContact = true;

    if (client && client.mRefId) {
      showContact = showContact && contact && contact.CL_ID && client.mRefId === contact.CL_ID;
    }

    return showContact;
  });

export const filterClientData = (clientData, project, agency) =>
  clientData.filter((client) => {
    let showClient = true;
    if (project && project.CL_ID) {
      showClient = showClient && project.CL_ID === client.mRefId;
    }
    if (agency && agency.mRefId) {
      showClient = showClient && client && client.AGENCY_ID && agency.mRefId === client.AGENCY_ID;
    }

    return showClient;
  });

export const getSelectedResourceFilteredData = (
  resourceType,
  resourceData,
  project,
  client,
  agency,
) => {
  switch (resourceType) {
    case 'client':
      return filterClientData(resourceData, project, agency);
    case 'agency':
      return filterAgencyData(resourceData, project, client);
    case 'contact':
      return filterContactData(resourceData, client);
    case 'project':
      return filterProjectData(
        resourceData,
        client ? client.mRefId : '',
        agency ? agency.mRefId : '',
      );
    default:
      return resourceData;
  }
};

export const getColumns = (resourceType) => {
  switch (resourceType) {
    case SCHEDULE_RESOURCE_TYPE.client: {
      return [
        {
          field: 'mTitle',
          headerName: 'TITLE',
          sortable: true,
        },
        {
          field: 'agency',
          headerName: 'AGENCY',
          sortable: true,
        },
      ];
    }
    case SCHEDULE_RESOURCE_TYPE.request: {
      return [
        {
          field: 'mTitle',
          headerName: 'TITLE',
          sortable: true,
        },
      ];
    }
    case SCHEDULE_RESOURCE_TYPE.contact:
    case SCHEDULE_RESOURCE_TYPE.agency: {
      return [
        {
          field: 'mTitle',
          headerName: 'TITLE',
          sortable: true,
        },
        {
          field: 'client',
          headerName: 'CLIENT',
          sortable: true,
        },
      ];
    }
    case SCHEDULE_RESOURCE_TYPE.packages: {
      return [
        {
          field: 'title',
          headerName: 'TITLE',
          sortable: true,
        },
      ];
    }
    case SCHEDULE_RESOURCE_TYPE.project:
    default: {
      return [
        {
          field: 'mTitle',
          headerName: 'TITLE',
          sortable: true,
        },
        {
          field: 'agency',
          headerName: 'AGENCY',
          sortable: true,
        },
        {
          field: 'client',
          headerName: 'CLIENT',
          sortable: true,
        },
      ];
    }
  }
};

export const getTabs = (disables = [], canAddRequirement, canAddResource) => [
  {
    label: 'Requests',
    value: SCHEDULE_RESOURCE_TYPE.request,
    disabled: !canAddRequirement || disables.includes(SCHEDULE_RESOURCE_TYPE.request),
  },
  {
    label: 'Requirements',
    value: SCHEDULE_RESOURCE_TYPE.packages,
    disabled: !canAddRequirement || disables.includes(SCHEDULE_RESOURCE_TYPE.packages),
  },
  {
    label: 'Placeholders',
    value: SCHEDULE_RESOURCE_TYPE.placeholder,
    disabled: !canAddRequirement || disables.includes(SCHEDULE_RESOURCE_TYPE.placeholder),
  },
  {
    label: 'Resources',
    value: SCHEDULE_RESOURCE_TYPE.resource,
    disabled: !canAddResource || disables.includes(SCHEDULE_RESOURCE_TYPE.resource),
  },
];

export const getTabbedColumns = (activeTab) => {
  switch (activeTab) {
    case SCHEDULE_RESOURCE_TYPE.request:
      return [
        {
          field: 'mTitle',
          headerName: 'Request Type',
          sortable: true,
        },
      ];
    case SCHEDULE_RESOURCE_TYPE.packages:
      return [
        {
          field: 'title',
          headerName: 'Requirements',
          sortable: true,
        },
      ];
    case SCHEDULE_RESOURCE_TYPE.placeholder:
      return [
        {
          field: 'category',
          headerName: 'Category',
          sortable: true,
        },
        {
          field: 'type',
          headerName: 'Type',
          sortable: true,
        },
      ];
    case SCHEDULE_RESOURCE_TYPE.resource:
      return [
        {
          field: 'category',
          headerName: 'Category',
          sortable: true,
        },
        {
          field: 'mTitle',
          headerName: 'Title',
          sortable: true,
        },
        {
          field: 'type',
          headerName: 'Type',
          sortable: true,
        },
      ];
    default:
      return [];
  }
};

export const getTabbedRows = (
  activeTab,
  availablePackages,
  selectedPlaceholder,
  scheduleResources,
) => {
  switch (activeTab) {
    case SCHEDULE_RESOURCE_TYPE.request:
      return scheduleResources[SCHEDULE_RESOURCE_TYPE.request];
    case SCHEDULE_RESOURCE_TYPE.packages:
      return availablePackages;
    case SCHEDULE_RESOURCE_TYPE.placeholder: {
      const categories = [...scheduleResources[SCHEDULE_RESOURCE_TYPE.category]];
      let rowId = 0;
      const flatCategories = categories
        .filter((item) => item.mTitle !== 'REQUEST')
        .map((category) => {
          if (category.items) {
            const categoryWithItem = category.items.map((item) => {
              rowId += 1;
              return {
                rowId,
                category: category.mTitle,
                categoryId: category.mRefId,
                type: item.title,
                typeId: item.itemId,
              };
            });
            return categoryWithItem;
          }
          rowId += 1;

          return {
            ...category,
            rowId,
            category: category.mTitle,
            categoryId: category.mRefId,
          };
        });
      return flatCategories.flat().filter((resource) => resource.category !== '');
    }
    case SCHEDULE_RESOURCE_TYPE.resource:
      if (selectedPlaceholder && selectedPlaceholder.categoryId) {
        return scheduleResources[SCHEDULE_RESOURCE_TYPE.resource].filter(
          (resource) =>
            resource.categoryId === selectedPlaceholder.categoryId &&
            resource.typeId === selectedPlaceholder.typeId,
        );
      }
      return scheduleResources[SCHEDULE_RESOURCE_TYPE.resource];
    default:
      return [];
  }
};

export const isOverlappingTime = (
  existingBookingStart,
  existingBookingEnd,
  bookingStart,
  bookingEnd,
  timezone,
) => {
  const parseZonedDate = toTimeZoneDate(timezone);
  const parsedExistingBookingStart = parseZonedDate(existingBookingStart);
  const parsedExistingBookingEnd = parseZonedDate(existingBookingEnd);
  const parsedBookingStart = parseZonedDate(bookingStart);
  const parsedBookingEnd = parseZonedDate(bookingEnd);
  return (
    (isAfter(parsedBookingStart, parsedExistingBookingStart) &&
      isBefore(parsedBookingStart, parsedExistingBookingEnd)) ||
    (isAfter(parsedBookingEnd, parsedExistingBookingStart) &&
      isBefore(parsedBookingEnd, parsedExistingBookingEnd)) ||
    isEqual(parsedBookingStart, parsedExistingBookingStart) ||
    isEqual(parsedBookingStart, parsedExistingBookingEnd) ||
    isEqual(parsedBookingEnd, parsedExistingBookingStart) ||
    isEqual(parsedBookingEnd, parsedExistingBookingEnd)
  );
};

export const getResourceAvailabilityInformation = (
  resourceInstance,
  resource,
  storyInformation,
  timezone,
) => {
  let isAssignable = true;
  let isAvailable = true;
  const parseZonedDate = toTimeZoneDate(timezone);

  if (resourceInstance && resourceInstance.length) {
    resourceInstance.forEach((item) => {
      const { mPublishingAt: resourceStart, mPublishingEnd: resourceEnd } = item;
      const { start: bookingStart, end: bookingEnd } = storyInformation;

      const isResourceOverlappingWithStory = isOverlappingTime(
        resourceStart,
        resourceEnd,
        addMinutes(parseZonedDate(bookingStart), BUFFER_TIME),
        subMinutes(parseZonedDate(bookingEnd), BUFFER_TIME),
        timezone,
      );

      isAssignable =
        isAssignable &&
        (!isResourceOverlappingWithStory ||
          (isResourceOverlappingWithStory && isResourceOverridable(item.type)));

      isAvailable =
        isAvailable &&
        (!isResourceOverlappingWithStory ||
          (isResourceOverlappingWithStory && item.mState === 'available'));
    });
  }

  return { isAssignable, isAvailable };
};

export const getResourceTimelineRows = (
  resourceInformation,
  selectedPlaceholder,
  selectedType,
  scheduleResources,
  storyInformation,
  showAvailableResourceOnly = false,
  timezone,
) => {
  let resources = scheduleResources[SCHEDULE_RESOURCE_TYPE.resource].map((item, index) => ({
    ...item,
    rowId: index,
  }));

  if (selectedPlaceholder && selectedPlaceholder.categoryId) {
    resources = scheduleResources[SCHEDULE_RESOURCE_TYPE.resource].filter((resource) => {
      const [categoryId, typeId] = resource.mRefId.split('-');

      return categoryId === selectedPlaceholder.categoryId && typeId === selectedPlaceholder.typeId;
    });
  }

  if (selectedType) {
    resources = resources.filter((resource) => {
      const [, typeId] = resource.mRefId.split('-');
      return typeId === selectedType;
    });
  }
  return resources.reduce((prev, curr) => {
    if (!prev.includes((i) => i.mId === curr.mId)) {
      // vis-timeline has problem with the key 'type'.
      // This is reserved, so we are removing the 'type' key from our object.

      const resourceInstance = resourceInformation.filter(
        (item) => item.resourceId === curr.resourceId,
      );

      const { isAssignable, isAvailable } = getResourceAvailabilityInformation(
        resourceInstance,
        curr,
        storyInformation,
        timezone,
      );

      const { type, ...rest } = curr;

      if (!showAvailableResourceOnly || isAvailable) {
        prev.push({
          ...rest,
          mId: curr.mRefId,
          mTitle: curr.mTitle,
          showButton: isAssignable,
        });
      }
    }
    return prev;
  }, []);
};

const isResourceOverridable = (type) => {
  switch (type) {
    case RESOURCE_ALLOCATION_TYPE.AVAILABLE_BLOCK:
    case RESOURCE_ALLOCATION_TYPE.SHIFT:
    case RESOURCE_ALLOCATION_TYPE.OTHER_NON_PAID_ACTIVITY_1:
    case RESOURCE_ALLOCATION_TYPE.OTHER_NON_PAID_ACTIVITY_2:
    case RESOURCE_ALLOCATION_TYPE.OTHER_NON_PAID_ACTIVITY_3:
    case RESOURCE_ALLOCATION_TYPE.OTHER:
    case RESOURCE_ALLOCATION_TYPE.VACATION:
    case RESOURCE_ALLOCATION_TYPE.SICK_DAY:
    case RESOURCE_ALLOCATION_TYPE.COMP_DAY:
    case RESOURCE_ALLOCATION_TYPE.SCHEDULED_DAY_OFF:
    case RESOURCE_ALLOCATION_TYPE.OUT_OF_SERVICE:
    case RESOURCE_ALLOCATION_TYPE.SCHEDULED_MAINTENANCE:
    case RESOURCE_ALLOCATION_TYPE.DAY_IN_LIEU:
    case RESOURCE_ALLOCATION_TYPE.SABBATICAL:
    case RESOURCE_ALLOCATION_TYPE.OTHER_PAID_ACTIVITY_1:
    case RESOURCE_ALLOCATION_TYPE.OTHER_PAID_ACTIVITY_2:
    case RESOURCE_ALLOCATION_TYPE.OTHER_PAID_ACTIVITY_3:
    case RESOURCE_ALLOCATION_TYPE.COMPANY_HOLIDAY:
    case RESOURCE_ALLOCATION_TYPE.UNPAID_SICK_DAY:
      return true;
    case RESOURCE_ALLOCATION_TYPE.BOOKING:
    default:
      return false;
  }
};

export const isBookingOverlapped = (resourceInformation, currentResource, timezone) => {
  const isOverlapped = resourceInformation
    .filter((item) => item.mId === currentResource.mId && item.type?.length)
    .reduce((prev, item) => {
      const { mPublishingAt: resourceStart, mPublishingEnd: resourceEnd } = item;
      const { mPublishingAt: currentResourceStart, mPublishingEnd: currentResourceEnd } =
        currentResource;
      return (
        prev ||
        isOverlappingTime(
          resourceStart,
          resourceEnd,
          currentResourceStart,
          currentResourceEnd,
          timezone,
        )
      );
    }, false);

  return isOverlapped;
};

export const getResourceTimelineItemClassName = (
  resourceTimelineItem,
  resourceInformation,
  timezone,
) => {
  if (resourceTimelineItem.type && resourceTimelineItem.type !== RESOURCE_ALLOCATION_TYPE.BOOKING) {
    return resourceTimelineItem.mState === 'unavailable' ? 'can_not_allocate' : 'can_allocate';
  }

  return isBookingOverlapped(resourceInformation, resourceTimelineItem, timezone)
    ? 'overlapped_booking'
    : '';
};

export const getResourceTimelineItems = (
  selectedPlaceholder,
  selectedType,
  resourceInformation,
  scheduleResources,
  storyInformation,
  timezone,
) => {
  const rows = getResourceTimelineRows(
    resourceInformation,
    selectedPlaceholder,
    selectedType,
    scheduleResources,
    storyInformation,
    undefined,
    timezone,
  );

  const items = resourceInformation
    .map((item) => {
      const groupIndex = rows.findIndex((row) => row.resourceId === item.resourceId);
      if (groupIndex > -1) {
        const resourceTimelineItem = {
          id: `${uuidv1()}-${item.mid}-${item.mRefId}`,
          group: rows[groupIndex].rowId,
          start: item.mPublishingAt,
          end: item.mPublishingEnd,
          content: item.mTitle,
          title: item.mTitle,
          editable: false,
          selectable: false,
          className: getResourceTimelineItemClassName(item, resourceInformation, timezone),
        };

        return resourceTimelineItem;
      }
    })
    .filter((item) => item !== undefined)
    .sort((resourceA, resourceB) => {
      if (resourceB.type > -1) {
        return 1;
      }

      return 0;
    });

  return items;
};

export const getTitleById = (scheduleResources, resourceId) => {
  const resourceIndex = scheduleResources.findIndex(
    (scheduleResource) => scheduleResource.mRefId === resourceId,
  );
  if (resourceIndex > -1) {
    return scheduleResources[resourceIndex].mTitle;
  }
  return 'N/A';
};

export const addTitleForResource = (scheduleResources, curr) => {
  if (curr && curr.AGENCY_ID) {
    curr.agency = getTitleById(scheduleResources[SCHEDULE_RESOURCE_TYPE.agency], curr.AGENCY_ID);
  }
  if (curr && curr.CL_ID) {
    curr.client = getTitleById(scheduleResources[SCHEDULE_RESOURCE_TYPE.client], curr.CL_ID);
  }
  if (curr.categoryId && curr.typeId) {
    const category = scheduleResources[SCHEDULE_RESOURCE_TYPE.category].find(
      (cat) => cat.mRefId === curr.categoryId,
    );
    if (category) {
      const type = category.items.find((item) => item.itemId === curr.typeId);
      curr.type = type && type.title;
      curr.category = category.mTitle;
    }
  }
  return curr;
};

export const addTitlesForResources = (scheduleResources) => {
  Object.keys(scheduleResources).forEach((key) => {
    scheduleResources[key] = scheduleResources[key].reduce((prev, curr) => {
      curr = addTitleForResource(scheduleResources, curr);
      prev.push(curr);
      return prev;
    }, []);
  });

  return scheduleResources;
};

export const isExistingScheduleEvent = (scheduleEvent) => scheduleEvent && scheduleEvent.mRefId;

export const flattenMetaData = (resource) => {
  const { mMetaData, ...rest } = resource;

  if (!mMetaData) {
    return resource;
  }

  const flatMetaData = mMetaData.reduce((prev, curr) => {
    prev[curr.key] = curr.value;
    return prev;
  }, {});

  return { ...rest, ...flatMetaData };
};

export const getResourceType = (resourceId, scheduleResources) =>
  Object.keys(scheduleResources).find((key) => {
    if (
      scheduleResources[key].findIndex(
        (item) => item.mRefId === resourceId || item.resourceId === resourceId,
      ) > -1
    ) {
      return key;
    }
  });

export const processScheduleResources = (resourcesResult) => {
  const reducedScheduleResources = resourcesResult.reduce((prev, curr) => {
    // eslint-disable-next-line no-prototype-builtins
    if (!prev.hasOwnProperty(curr.mId)) {
      // eslint-disable-next-line no-param-reassign
      prev[curr.mId] = [];
    }
    prev[curr.mId].push(curr);
    return prev;
  }, {});

  const preprocessedScheduleResources = {};

  Object.keys(reducedScheduleResources).forEach((key) => {
    preprocessedScheduleResources[key] = getPreProcessedScheduleResourceData(
      reducedScheduleResources[key],
    );

    preprocessedScheduleResources[key].sort((itemA, itemB) => {
      if (itemA.mTitle.toLowerCase() > itemB.mTitle.toLowerCase()) {
        return 1;
      }
      return -1;
    });

    if (key === SCHEDULE_RESOURCE_TYPE.category) {
      preprocessedScheduleResources[key].forEach((category) => {
        category.items.sort((itemA, itemB) => {
          if (itemA.title.toLowerCase() > itemB.title.toLowerCase()) {
            return 1;
          }
          return -1;
        });
      });
    }
  });

  return addTitlesForResources(preprocessedScheduleResources);
};

export const preProcessBookingResources = (scheduleResources, bookingResources) => {
  const bookingResourcesMap = [];
  const roots = [];

  // Initializing...
  for (let i = 0; i < bookingResources.length; i += 1) {
    // const { mMetaData, ...rest } = bookingResources[i];
    const currentBookingResource = flattenMetaData(bookingResources[i]);
    bookingResources[i] = currentBookingResource;
    bookingResourcesMap[currentBookingResource.sessionId] = i;
    bookingResources[i].children = [];
    bookingResources[i].resourceType = getResourceType(
      bookingResources[i].resourceId,
      scheduleResources,
    );
  }

  const bookingResourcesWithCategoryAndType = bookingResources.map((item) =>
    addTitleForResource(scheduleResources, item),
  );

  for (let i = 0; i < bookingResourcesWithCategoryAndType.length; i += 1) {
    const node = bookingResourcesWithCategoryAndType[i];
    bookingResourcesWithCategoryAndType[i].rowId = i;
    const { pSessionId, sessionId } = node;

    if (!pSessionId || pSessionId === sessionId) {
      roots.push(node);
    } else {
      bookingResourcesWithCategoryAndType[bookingResourcesMap[pSessionId]].children.push(node);
    }
  }
  return roots;
};

export const addMetadataField = (metadata, key, value) => {
  if (value) {
    metadata.push({ key, value });
  }
};

export const getBooking = (bookingInfo) => {
  const {
    mId,
    mRefId,
    mTitle,
    mPublishingAt,
    mPublishingEnd,
    mResourceId,
    pSessionId,
    resourceId,
    seqNumber,
    sessionId,
    categoryId,
    typeId,
    title,
  } = bookingInfo;

  const mMetaData = [];

  addMetadataField(mMetaData, 'sessionId', sessionId);
  addMetadataField(mMetaData, 'pSessionId', pSessionId);
  addMetadataField(mMetaData, 'resourceId', resourceId);
  addMetadataField(mMetaData, 'seqNumber', seqNumber);
  addMetadataField(mMetaData, 'typeId', typeId);
  addMetadataField(mMetaData, 'categoryId', categoryId);

  const booking = {
    mId,
    mPublishingAt,
    mPublishingEnd,
    mMetaData,
    mTitle: mTitle || title,
  };

  if (mRefId) booking.mRefId = mRefId;

  if (mResourceId) booking.mResourceId = mResourceId;
  return booking;
};

export const getBookings = (requests = [], bookings = []) => {
  requests.forEach((request) => {
    bookings.push(getBooking(request));
    const { children } = request;
    if (!children || !children.length) return;
    getBookings(children, bookings);
  });

  return bookings;
};

export const getBookingForNewWorkOrder = (bookingInfo) => {
  const {
    mId,
    mRefId,
    mPublishingAt,
    mPublishingEnd,
    categoryId,
    typeId,
    title,
    itemId,
    mTitle,
    resourceId, // only id
    mResourceId, // id including category and type id
  } = bookingInfo;

  const mMetaData = [];

  if (resourceId) {
    addMetadataField(mMetaData, 'resourceId', resourceId || mRefId);
  } else if (itemId) {
    addMetadataField(mMetaData, 'resourceId', itemId);
  } else {
    addMetadataField(mMetaData, 'resourceId', 0);
  }

  addMetadataField(mMetaData, 'typeId', typeId);
  addMetadataField(mMetaData, 'categoryId', categoryId);

  const booking = {
    mId,
    mPublishingAt,
    mPublishingEnd,
    mMetaData,
    mTitle: mTitle || title,
  };

  if (mResourceId) {
    booking.mResourceId = mResourceId;
  }

  return booking;
};

export const getBookingsForNewWorkOrder = (requests, bookings) => {
  requests.forEach((request) => {
    bookings.push(getBookingForNewWorkOrder(request));
    const { children } = request;
    if (!children || !children.length) return;
    getBookingsForNewWorkOrder(children, bookings);
  });
  return bookings;
};
