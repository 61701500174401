import { useDrag } from 'react-dnd';

import DragMultiple from 'assets/images/rundown/DragMultiple.png';
import DragSingle from 'assets/images/rundown/DragSingle.png';
import { MoveItemProps, useStoryPaneMolecule } from 'screens/storyV2/store/storyPane';
import dndTypes from 'utils/dndTypes';

export interface DropItem {
  id: string;
  type: string;
  storyId: string;
  unscoped?: boolean;
}

type DropEndCallback = (props: MoveItemProps) => void;

const typeToItemType = {
  [dndTypes.STORY_NOTE]: 'note',
  [dndTypes.STORY_INSTANCE]: 'instance',
} as const;

const useCommonDrag = (
  selectedItems: string[],
  onDropEnd: DropEndCallback,
  { id, type, storyId, unscoped }: DropItem,
) => {
  const { useSelectedStoryInstances, useSelectedStoryNotes } = useStoryPaneMolecule();

  const [, setSelectedInstances] = useSelectedStoryInstances();
  const [, setSelectedNotes] = useSelectedStoryNotes();

  const selectionCount = selectedItems.length;

  const [{ isDragging, draggedId }, dragRef, preview] = useDrag({
    type,
    item: (): DropItem | undefined => {
      if (!selectedItems.includes(id)) return;

      /* In search preview the story is scoped but
      it doesn't work with the selected item (notes/instances).
      We are manually updating the selected item here. */
      if (unscoped && type === dndTypes.STORY_NOTE) {
        setSelectedNotes([id]);
      }
      if (unscoped && type === dndTypes.STORY_INSTANCE) {
        setSelectedInstances([id]);
      }
      return {
        id,
        type,
        storyId,
        unscoped,
      };
    },
    end: (_, monitor) => {
      if (!monitor.didDrop()) return;
      const dropResult = monitor.getDropResult<{ storyId: string }>();
      if (dropResult?.storyId)
        onDropEnd({
          itemType: typeToItemType[type],
          targetStoryId: dropResult.storyId,
          parentStoryId: storyId,
        });
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      draggedId: monitor.getItem()?.id,
    }),
  });

  const previewSrc = selectionCount > 1 ? DragMultiple : DragSingle;

  return {
    isDragging,
    draggedId,
    dragRef,
    previewConnect: preview,
    previewSrc,
  };
};

export default useCommonDrag;
