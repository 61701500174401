import { useQuery } from '@apollo/client';

import GET_ASSETS from 'operations/queries/getAssets';
import { Asset } from 'types';
import { GetMemberInput, MemberTypeEnum } from 'types/graphqlTypes';

type GetAssetsReturnType = {
  getAssets: Asset[];
};

type GetAssetsInputType = {
  input: GetMemberInput;
};

const useGetAssets = ({ mId, memberType }: { mId: string; memberType: MemberTypeEnum }) => {
  const { data, error, loading } = useQuery<GetAssetsReturnType, GetAssetsInputType>(GET_ASSETS, {
    variables: {
      input: {
        mId,
        mType: memberType,
      },
    },
    fetchPolicy: 'cache-and-network',
  });

  return { data: data?.getAssets, error, loading };
};

export default useGetAssets;
