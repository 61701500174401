import { css } from '@emotion/react';
import styled from '@emotion/styled/macro';

import Text from 'components/text/Text';
import { HStack } from 'layouts/box/Box';
import transientOptions from 'theme/helpers';

interface ContainerProps {
  $size?: number;
  $overflow?: number;
  $dark?: boolean;
}

export const OverflowText = styled(Text)`
  user-select: none;
`;

export const Container = styled('div', transientOptions)<ContainerProps>`
  display: flex;
  align-items: center;
  ${({ onClick, theme, $dark }) =>
    onClick &&
    css`
      cursor: pointer;
      :hover {
        ${OverflowText} {
          text-decoration: underline;
          text-underline-offset: 2px;
          color: ${$dark
            ? theme.palette.dina.whiteHighEmphasis
            : theme.palette.dina.highEmphasis} !important;
        }
      }
    `}
  ${({ $size = 32 }) => css`
    > :not(:first-of-type) {
      margin-left: -${Math.ceil($size / 5)}px;
    }
  `}
  ${({ $overflow }) =>
    $overflow
      ? css`
          > :last-of-type {
            margin-left: 2px;
          }
        `
      : ''}
`;

export const TooltipText = styled(Text)`
  white-space: pre-line;
`;

export const AssigneeWrapper = styled(HStack, transientOptions)<{
  $size: number;
  $overflow?: boolean;
  $disabled?: boolean;
}>`
  cursor: ${({ $disabled }) => ($disabled ? 'default' : 'pointer')};
  height: ${({ $size }) => $size}px;
  gap: 4px;
  padding-left: ${({ $size }) => ($size >= 32 ? 4 : 2)}px;
  padding-right: ${({ $size, $overflow }) => ($size >= 32 ? 4 : 2) + ($overflow ? 2 : 0)}px;
  border-radius: ${({ $size }) => $size / 2}px;
  border: 1px solid ${({ theme }) => theme.palette.dina.buttonBorderOutlined};
  &:hover svg path {
    fill-opacity: ${({ $disabled }) => !$disabled && 1};
  }
`;

export const AssignButton = styled('div', transientOptions)<{
  $size: number;
}>`
  margin: -2px;
  height: ${({ $size }) => $size - 8}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
