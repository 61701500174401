import React, { MouseEventHandler, useEffect, useRef } from 'react';

import { Button, ButtonProps } from 'components/buttons';
import { LoadingButton } from 'components/buttons/LoadingButton';
import Radio from 'components/buttons/radioButton';
import { TwoStepButton } from 'components/buttons/TwoStepButton';
import Divider from 'components/divider';
import InputField from 'components/input/Input';
import Text from 'components/text/Text';
import useToast from 'components/toast/useToast';
import { Box, VStack } from 'layouts/box/Box';
import { CheckboxWithLabel } from 'lib/checkbox';
import { DialogClose } from 'lib/dialog';

import { DialogDataModel, useDataModel } from '../atoms';

import Select from './select/Select';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from './table/Table';

import { DividerWrapper, SectionTitleWrapper, StyledLabel } from './styled';

/**
 * This component is used by the the DialogBuilder
 * It uses the DialogMolecule to store the data model.
 * The components are tailored to solve common dialog use cases.
 */

type CommonDialogProps = {
  children: React.ReactNode;
  noMargin?: boolean;
};

// ─── Divider ──────────────────────────────────────────────────────────────────
export const DialogGroup = ({ children, noMargin }: CommonDialogProps) => (
  <VStack container width="100%" margin={noMargin ? '0' : '8px 0 0 0'}>
    {children}
  </VStack>
);

// ─── Divider ──────────────────────────────────────────────────────────────────
export const DialogDivider = () => (
  <DividerWrapper>
    <Divider />
  </DividerWrapper>
);

// ─── SectionTitle ──────────────────────────────────────────────────────────────
export const DialogSectionTitle = ({ children }: CommonDialogProps) => {
  return (
    <SectionTitleWrapper>
      <Text variant="overline" as="p">
        {children}
      </Text>
    </SectionTitleWrapper>
  );
};

type DialogLabelProps = {
  children: React.ReactNode;
  asChild?: boolean;
  htmlFor?: string;
};

// ─── Label ────────────────────────────────────────────────────────────────────
export const DialogLabel = ({ children, asChild, htmlFor }: DialogLabelProps) => {
  return (
    <StyledLabel asChild={asChild} htmlFor={htmlFor}>
      {children}
    </StyledLabel>
  );
};

// ─── InputField ───────────────────────────────────────────────────────────────
interface DialogInputFieldProps {
  initialValue?: string;
  placeholder?: string;
  propertyName?: string;
  autoFocus?: boolean;
  required?: boolean;
  disabled?: boolean;
  id?: string;
  onEnter?: (dataModel: DialogDataModel) => void;
}

export const DialogInputField = ({
  initialValue = '',
  placeholder = 'Type here...',
  propertyName = 'input',
  autoFocus = true,
  required = false,
  disabled = false,
  id,
  onEnter = () => {},
}: DialogInputFieldProps) => {
  const [dataModel, setDataModel] = useDataModel();
  const inputRef = useRef<HTMLInputElement>(null);

  const updateInputValue = (newValue: string) => {
    setDataModel({ ...dataModel, [propertyName]: newValue });
  };

  useEffect(() => {
    if (autoFocus && inputRef?.current) {
      inputRef.current.focus();
    }
  }, []);

  const value = (dataModel?.[propertyName] as string) ?? initialValue;

  return (
    <InputField
      id={id}
      ref={inputRef}
      required={required}
      autoFocus={autoFocus}
      placeholder={placeholder}
      disabled={disabled}
      value={value}
      onChange={(event) => updateInputValue(event.target.value)}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          if (dataModel) onEnter(dataModel);
        }
      }}
    />
  );
};

// ─── Select ───────────────────────────────────────────────────────────────────
interface DialogSelectProps {
  label: string;
  propertyName?: string;
  initialValue?: string;
  items: { value: string; label: string }[];
}

export const DialogSelect = ({
  label,
  propertyName = 'select',
  initialValue,
  items,
}: DialogSelectProps) => {
  const [dataModel, setDataModel] = useDataModel();

  const onChange = (val: string) => {
    setDataModel({ ...dataModel, [propertyName]: val });
  };

  const value = (dataModel?.[propertyName] as string) ?? initialValue;

  return <Select label={label} value={value} onValueChange={onChange} items={items} />;
};

// ─── Radio ────────────────────────────────────────────────────────────────────
interface DialogRadioProps {
  label?: string;
  propertyName?: string;
  initialValue: string;
  items: { value: string; label: string }[];
}

export const DialogRadio = ({
  propertyName = 'radio',
  initialValue,
  items,
  label,
}: DialogRadioProps) => {
  const [dataModel, setDataModel] = useDataModel();

  const onChange = (val: string) => {
    setDataModel({ ...dataModel, [propertyName]: val });
  };

  const value = (dataModel?.[propertyName] as string) ?? initialValue;

  return (
    <Box container justifyContent="space-between">
      {label && (
        <Text variant="listItemLabel" color="highEmphasis">
          {label}
        </Text>
      )}
      <Box container gap="4px">
        {items.map((item) => {
          const selected = item.value === value;
          return (
            <Box key={item.label} container>
              <Radio selected={selected} onClick={() => onChange(item.value)} />
              <Text
                variant="caption"
                color={selected ? 'highEmphasis' : 'mediumEmphasis'}
                onClick={() => onChange(item.value)}
                style={{ cursor: 'pointer' }}
              >
                {item.label}
              </Text>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

// ─── Table ───────────────────────────────────────────────────────────────────

type CellType = {
  id: string;
  value: string | number | React.ReactElement;
};

export type RowType = {
  id: string;
  cells: CellType[];
};
interface DialogTableProps {
  headers: string[];
  rows: RowType[];
}

export const DialogTable = ({ headers, rows }: DialogTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          {headers.map((header) => (
            <TableHead key={header}>{header}</TableHead>
          ))}
        </TableRow>
      </TableHeader>
      <TableBody>
        {rows?.length > 0 &&
          rows.map((row) => (
            <TableRow key={row.id}>
              {row.cells.map((cell) => (
                <TableCell key={cell.id}>{cell.value}</TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
};

// ─── Checkbox ────────────────────────────────────────────────────────────────
interface DialogCheckboxProps {
  propertyName?: string;
  initialValue?: boolean;
  label?: string;
  /** If required, confirm button will be disabled until checkbox is checked */
  required?: boolean;
}

export const DialogCheckbox = ({
  propertyName = 'checkbox',
  initialValue = false,
  label = '',
  required = false,
}: DialogCheckboxProps) => {
  const [dataModel, setDataModel] = useDataModel();

  const isSelected = (dataModel?.[propertyName] as boolean) ?? initialValue;

  const dataModelErrors = [...((dataModel?.errors as string[]) ?? [])];

  const handleCheckChanged = () => {
    if (required && isSelected && dataModelErrors.indexOf(propertyName) === -1) {
      dataModelErrors.push(propertyName);
    } else {
      const index = dataModelErrors.indexOf(propertyName);
      if (index > -1) {
        dataModelErrors.splice(index, 1);
      }
    }

    setDataModel({
      ...dataModel,
      [propertyName]: !isSelected,
      errors: dataModelErrors,
    });
  };

  /** Update data model on mount */
  useEffect(() => {
    if (!isSelected) {
      if (required && dataModelErrors.indexOf(propertyName) === -1) {
        dataModelErrors.push(propertyName);
      } else {
        const index = dataModelErrors.indexOf(propertyName);
        if (index > -1) {
          dataModelErrors.splice(index, 1);
        }
      }

      setDataModel({ ...dataModel, [propertyName]: initialValue, errors: dataModelErrors });
    }
  }, []);

  return <CheckboxWithLabel label={label} checked={isSelected} onClick={handleCheckChanged} />;
};

// ─── Buttons ──────────────────────────────────────────────────────────────────
interface DialogCancelButtonProps extends Omit<ButtonProps, 'children'> {
  label?: string;
  onCancel?: () => void;
}

export const DialogCancelButton = ({
  label = 'Cancel',
  onCancel,
  ...rest
}: DialogCancelButtonProps) => {
  const [, setDataModel] = useDataModel();

  const handleCancel: MouseEventHandler<HTMLButtonElement> = () => {
    if (onCancel) {
      onCancel();
    }
    setDataModel(null);
  };

  return (
    <DialogClose asChild>
      <Button
        variant="outlined"
        usage="outlined"
        onClick={handleCancel}
        width={128}
        size="md"
        {...rest}
      >
        {label}
      </Button>
    </DialogClose>
  );
};

interface DialogConfirmButtonProps {
  icon?: React.ReactElement;
  label?: string;
  autoFocus?: boolean;
  loading?: boolean;
  /** Confirm and send data model changes */
  onConfirm?: (dataModel: DialogDataModel | undefined) => void | Promise<void>;
  disabled?: boolean;
  /* Use without data model   */
  onClick?: () => void | Promise<void>;
  title?: React.ReactElement | string;
  usage?: ButtonProps['usage'];
}

export const DialogConfirmButton = ({
  icon,
  label = 'Ok',
  autoFocus = false,
  disabled = false,
  loading = false,
  usage = 'cta',
  title,
  /** Returns data model changes */
  onConfirm = () => {},
  /** Use when controlled (no dialog data model) */
  onClick,
}: DialogConfirmButtonProps) => {
  const [dataModel, setDataModel] = useDataModel();
  const { errorToast } = useToast();

  const handleConfirm: MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    if (onClick) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      onClick();
      return;
    }

    if (onConfirm) {
      Promise.resolve(onConfirm(dataModel ?? undefined))
        .then(() => setDataModel(null))
        .catch(errorToast);
    }
  };

  /** Disabled if there are errors in the data model or if disabled is set from the outside */
  const errors = (dataModel?.errors as string[]) ?? [];
  const isDisabled = disabled || errors.length > 0;

  return (
    <LoadingButton
      loading={loading}
      variant="contained"
      autoFocus={autoFocus}
      disabled={isDisabled}
      usage={usage}
      width={128}
      size="md"
      onClick={handleConfirm}
      title={title}
      startIcon={icon}
    >
      {label}
    </LoadingButton>
  );
};

interface DialogTwoStepButtonProps {
  icon1?: React.ReactElement;
  icon2?: React.ReactElement;
  label1?: string;
  label2?: string;
  disabled?: boolean;
  loading?: boolean;
  onConfirm: (dataModel: DialogDataModel | undefined) => void | Promise<void>;
}

export const DialogTwoStepButton = ({
  icon1,
  icon2,
  label1 = 'Confirm',
  label2 = 'Click again to confirm',
  disabled = false,
  loading = false,
  onConfirm,
}: DialogTwoStepButtonProps) => {
  const [dataModel] = useDataModel();

  const handleConfirm = () => {
    // eslint-disable-next-line no-console
    Promise.resolve(onConfirm(dataModel ?? undefined)).catch((e) => console.error('Error', e));
  };

  return (
    <TwoStepButton
      loading={loading}
      onConfirm={handleConfirm}
      disabled={disabled}
      icon1={icon1}
      icon2={icon2}
      label1={label1}
      label2={label2}
    />
  );
};
