import { useMemo } from 'react';

import { useAllMembersKeyed } from 'store';
import { User } from 'types';

type ItemEnricher<I, O> = (input: I, user: User | undefined) => O | undefined;

/**
 * Enriches an array with information about associated users
 * @param inputs     The incoming array
 * @param getUserId  Gets the ID of the associated user from an incoming item
 * @param enrichItem An {@link ItemEnricher}
 * @returns          An array of resulting enriched items
 */
export default function useUserEnrichedItems<I, O>(
  inputs: readonly I[],
  getUserId: (input: I) => string,
  enrichItem: ItemEnricher<I, O>,
) {
  const [allMembers] = useAllMembersKeyed();
  const enrichedItems = useMemo(() => {
    const result: O[] = [];
    inputs.forEach((input) => {
      const user = allMembers[getUserId(input)];
      const enrichedItem = enrichItem(input, user);
      if (enrichedItem) result.push(enrichedItem);
    });
    return Object.freeze(result);
  }, [inputs, getUserId, enrichItem, allMembers]);
  return enrichedItems;
}
