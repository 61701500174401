import { useCallback } from 'react';

import { usePolicies } from 'store';
import { AccessTypeEnum, ResourcePolicy } from 'types/graphqlTypes';

const getResourcePermission = (resource: ResourcePolicy, action: string, defaultValue: boolean) => {
  const { permissions = [] } = resource ?? {};
  const permission = permissions.find((p) => p.action === action);
  if (!permission) return defaultValue;
  if (permission.access && permission.access === AccessTypeEnum.Allow) return true;
  return false;
};

const useCheckUserRight = () => {
  const [policies] = usePolicies();

  const checkPermission = useCallback(
    (resourceName: string, action: string) => {
      const defaultPermission = resourceName !== 'feature';
      const resource = policies.find((r) => r.resourceName === resourceName);
      if (!resource) return defaultPermission;
      return getResourcePermission(resource, action, defaultPermission);
    },
    [policies],
  );

  const checkPermissionStrict = useCallback(
    (resourceName: string, action: string) => {
      const resource = policies.find((r) => r.resourceName === resourceName);
      if (!resource) return false;
      return getResourcePermission(resource, action, false);
    },
    [policies],
  );

  const check = useCallback(
    (resourceName: string, action: string, strict = false) => {
      if (!strict) return checkPermission(resourceName, action);
      return checkPermissionStrict(resourceName, action);
    },
    [checkPermission, checkPermissionStrict],
  );

  return [check];
};

export const useCanSeePlugins = () => {
  const [checkUserRight] = useCheckUserRight();
  const canAccessPlugins = checkUserRight('feature', 'search_plugin');
  return { canAccessPlugins };
};

export const useCanSeeOrderManagement = () => {
  const [checkUserRight] = useCheckUserRight();
  const canAccessSpaces = checkUserRight('space', 'access');
  const canAccessOrderManagement = checkUserRight('task', 'access') && canAccessSpaces;
  return { canAccessOrderManagement };
};

export const useCanAdministrateSearches = () => {
  const [checkUserRight] = useCheckUserRight();
  const canAdministrate = checkUserRight('search', 'saved-search-administrator', true);
  return { canAdministrate };
};

export default useCheckUserRight;
