import { css } from '@emotion/react';
import styled from '@emotion/styled';

import { ReactComponent as ArchiveOff } from 'assets/icons/systemicons/ellipsis_menu/archive_off.svg';
import { Button } from 'components/buttons';
import Divider from 'components/divider';
import transientOptions from 'theme/helpers';

// Toolbar.tsx
export const ToolbarContainer = styled('div')`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(max-content, 1fr) minmax(25%, 40%) 1fr;
  align-items: center;
  gap: 8px;
`;

export const TitleDropdownWrapper = styled('div')`
  width: 100%;
  justify-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Options = styled('div')`
  justify-self: end;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 4px;
`;

export const ItemsWrapper = styled('div')`
  width: 320px;
  border: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
  border-radius: 6px;
  padding-block: 8px;
  margin-block: -8px;
`;

export const VDivider = styled(Divider)`
  height: 32px;
  width: 1px;
  margin-inline: 8px;
  background: ${({ theme }) => theme.palette.dina.whiteWatermark};
`;

// Publish.tsx
export const CalenderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button, transientOptions)<{ $dark?: boolean }>`
  ${({ $dark, theme }) =>
    $dark
      ? css`
          border-color: ${theme.palette.dina.whiteButtonBorderOutlined};
          :hover {
            border-color: ${theme.palette.dina.whiteButtonBorderOutlined};
            background-color: ${theme.palette.dina.whiteHoverOverlay};
          }
          svg {
            path {
              fill: ${theme.palette.dina.whiteMediumEmphasis};
            }
          }
        `
      : ''}
`;

StyledButton.defaultProps = {
  variant: 'outlined',
  usage: 'text',
  height: 32,
};

export const LoadingIconWrapper = styled('div')`
  position: relative;
  width: 24px;
  height: 24px;
`;

export const FontSizeContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 0px 8px 8px;
  margin-bottom: 4px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.dina.dividerLight};
`;

export const IconWrapper = styled('div')`
  & path {
    fill: ${({ theme }) => theme.palette.dina.iconSelectedWhite};
    fill-opacity: 0.54;
  }
  &:hover {
    & path {
      fill-opacity: 1;
    }
  }
`;

export const ArchiveIcon = styled(ArchiveOff)`
  path {
    fill: ${({ theme }) => theme.palette.dina.errorText} !important;
    fill-opacity: 1;
  }
`;
