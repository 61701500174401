import { useCallback } from 'react';
import { TZDate } from '@date-fns/tz';
import {
  addDays,
  addHours,
  addMilliseconds,
  addMinutes,
  addMonths,
  addSeconds,
  addWeeks,
  addYears,
  setDate,
  setHours,
  setMilliseconds,
  setMinutes,
  setMonth,
  setSeconds,
  setYear,
  subDays,
  subHours,
  subMinutes,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns';

import { DateType, TimezoneConverter, toTimeZoneDate } from './useDateTimeUtils';
import useTimezoneSettings from './useTimezoneSettings';

type DateManipulateFunction<Options = unknown> = (
  date: TZDate,
  amount: number,
  options?: Options,
) => Date;

const withTimezoneDateManipulation =
  (convertToTimezoneDate: TimezoneConverter) =>
  <Options = unknown>(manipulateFn: DateManipulateFunction<Options>) =>
  (date: DateType, amount: number, options?: Options) =>
    manipulateFn(convertToTimezoneDate(date), amount, options);

const useDateManipulationUtils = () => {
  const timezone = useTimezoneSettings();

  const timezoneSafeFn = useCallback(withTimezoneDateManipulation(toTimeZoneDate(timezone)), [
    timezone,
  ]);

  return {
    addDays: timezoneSafeFn(addDays),
    addHours: timezoneSafeFn(addHours),
    addMilliseconds: timezoneSafeFn(addMilliseconds),
    addMinutes: timezoneSafeFn(addMinutes),
    addMonths: timezoneSafeFn(addMonths),
    addSeconds: timezoneSafeFn(addSeconds),
    addWeeks: timezoneSafeFn(addWeeks),
    addYears: timezoneSafeFn(addYears),
    setHours: timezoneSafeFn(setHours),
    setMilliseconds: timezoneSafeFn(setMilliseconds),
    setMinutes: timezoneSafeFn(setMinutes),
    setMonth: timezoneSafeFn(setMonth),
    setDate: timezoneSafeFn(setDate),
    setSeconds: timezoneSafeFn(setSeconds),
    setYear: timezoneSafeFn(setYear),
    subDays: timezoneSafeFn(subDays),
    subHours: timezoneSafeFn(subHours),
    subMinutes: timezoneSafeFn(subMinutes),
    subMonths: timezoneSafeFn(subMonths),
    subWeeks: timezoneSafeFn(subWeeks),
    subYears: timezoneSafeFn(subYears),
  };
};

export default useDateManipulationUtils;
