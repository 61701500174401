/**
 * Formats a duration given in miliseconds to string representation
 *
 * @param durationInMs Duration given in miliseconds
 * @returns string representation of given duration (i.e. 1m 10s)
 */
export const formatDuration = (durationInMs: number) => {
  const convertedDuration = new Date(1000 * Math.round(durationInMs / 1000));
  const hours = convertedDuration.getUTCHours();
  const minutes = convertedDuration.getUTCMinutes();
  const seconds = convertedDuration.getUTCSeconds();
  return (
    (hours ? `${hours}h ` : '') + (minutes ? `${minutes}m ` : '') + (seconds ? `${seconds}s` : '')
  );
};

/**
 * Finds the difference of two duration and formats in string with positive/negative sign
 *
 * @param duration1 First duration given in miliseconds
 * @param duration2 Second duration given in miliseconds
 * @returns string representation of difference between two duration with sign
 */
export const convertDifferenceInString = (durationInMs1: number, durationInMs2: number) => {
  const sign = durationInMs1 !== durationInMs2 && (durationInMs1 > durationInMs2 ? '-' : '+');
  const timeOver = formatDuration(Math.abs(durationInMs1 - durationInMs2));
  return `${sign || ''}${timeOver}`;
};
