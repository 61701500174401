import { useCallback } from 'react';
import { tz } from '@date-fns/tz';
import {
  eachDayOfInterval,
  endOfDay,
  endOfISOWeek,
  format,
  formatDistanceStrict,
  FormatDistanceStrictOptions,
  formatDistanceToNow,
  FormatOptions,
  getDate,
  getHours,
  getISODay,
  getISOWeek,
  getISOWeekYear,
  getMilliseconds,
  getMinutes,
  getMonth,
  getSeconds,
  getYear,
  Interval,
  IntervalOptions,
  lastDayOfISOWeek,
  lastDayOfMonth,
  parseISO,
  ParseISOOptions,
  startOfDay,
  startOfISOWeek,
  startOfMinute,
  startOfMonth,
} from 'date-fns';

import { DateType, TimezoneConverter, toTimeZoneDate } from './useDateTimeUtils';
import useTimezoneSettings from './useTimezoneSettings';

type ExpectedResult = Date | number | string;
type DateFormatFunction<Options = unknown, Result extends ExpectedResult = ExpectedResult> = (
  date: DateType,
  options?: Options,
) => Result;

const withTimezoneDateFormat =
  (convertToTimezoneDate: TimezoneConverter) =>
  <Options = unknown, Result extends ExpectedResult = ReturnType<DateFormatFunction<Options>>>(
    formatFn: (date: DateType, options?: Options) => Result,
  ) =>
  (date: DateType, options?: Options): Result =>
    formatFn(convertToTimezoneDate(date), options);

const useDateFormattingUtils = () => {
  const timezone = useTimezoneSettings();

  const parseDate = useCallback(toTimeZoneDate(timezone), [timezone]);
  const timezoneSafeFormatFn = useCallback(withTimezoneDateFormat(parseDate), [parseDate]);

  const parseISOTZ = useCallback(
    (date: string, options: ParseISOOptions = {}) =>
      parseISO(date, { in: tz(timezone), ...options }),
    [timezone],
  );

  const formatTZ = useCallback(
    (date: DateType, formatStr: string, options?: FormatOptions) =>
      format(parseDate(date), formatStr, options),
    [parseDate],
  );

  const eachDayOfIntervalTZ = useCallback(
    (interval: Interval, options?: IntervalOptions) =>
      eachDayOfInterval(
        { start: parseDate(interval.start), end: parseDate(interval.end) },
        options,
      ),
    [],
  );
  const formatDistanceStrictTZ = useCallback(
    (laterDate: DateType, earlierDate: DateType, options?: FormatDistanceStrictOptions) =>
      formatDistanceStrict(parseDate(laterDate), parseDate(earlierDate), options),
    [parseDate],
  );

  return {
    eachDayOfInterval: eachDayOfIntervalTZ,
    format: formatTZ,
    formatDistanceStrict: formatDistanceStrictTZ,
    formatDistanceToNow: timezoneSafeFormatFn(formatDistanceToNow),
    getISODay: timezoneSafeFormatFn(getISODay),
    getISOWeek: timezoneSafeFormatFn(getISOWeek),
    getISOWeekYear: timezoneSafeFormatFn(getISOWeekYear),
    getHours: timezoneSafeFormatFn(getHours),
    getMilliseconds: timezoneSafeFormatFn(getMilliseconds),
    getMinutes: timezoneSafeFormatFn(getMinutes),
    getSeconds: timezoneSafeFormatFn(getSeconds),
    getMonth: timezoneSafeFormatFn(getMonth),
    getDate: timezoneSafeFormatFn(getDate),
    getYear: timezoneSafeFormatFn(getYear),
    endOfDay: timezoneSafeFormatFn(endOfDay),
    endOfISOWeek: timezoneSafeFormatFn(endOfISOWeek),
    lastDayOfISOWeek: timezoneSafeFormatFn(lastDayOfISOWeek),
    lastDayOfMonth: timezoneSafeFormatFn(lastDayOfMonth),
    parseISO: parseISOTZ,
    startOfDay: timezoneSafeFormatFn(startOfDay),
    startOfISOWeek: timezoneSafeFormatFn(startOfISOWeek),
    startOfMinute: timezoneSafeFormatFn(startOfMinute),
    startOfMonth: timezoneSafeFormatFn(startOfMonth),
  };
};

export default useDateFormattingUtils;
