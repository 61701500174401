import isEmpty from 'lodash/isEmpty';

import { isMiniMember } from 'components/mdfEditor/fields/relation/relation-utils';
import { CommandToolbarProps, SortOption } from 'features/command/command-types';
import { isObject } from 'types';
import { Metadata, SearchMetadata } from 'types/forms/forms';
import {
  AggregateByInput,
  FieldValue,
  SearchItemInput,
  SearchItemTypeEnum,
  SortByInput,
  SortDirection,
  SortType,
} from 'types/graphqlTypes';

import { SearchableKeys } from './types';

const replacementTypes: Partial<{ [key in SearchItemTypeEnum]: SearchItemTypeEnum }> = {
  [SearchItemTypeEnum.story]: SearchItemTypeEnum.res_story,
  [SearchItemTypeEnum.pitch]: SearchItemTypeEnum.res_pitch,
  [SearchItemTypeEnum.archived_story]: SearchItemTypeEnum.archived_res_story,
  [SearchItemTypeEnum.archived_pitch]: SearchItemTypeEnum.archived_res_pitch,
};

const sortFields: Record<SortType, string> = {
  createdAt: 'mCreatedAt',
  scheduledAt: 'mPublishingAt',
  updatedAt: 'mUpdatedAt',
  totalInstances: 'totalInstances',
  title: 'mTitle',
  priority: 'mPriority',
};

export interface DateRange {
  startDate: string;
  endDate: string | null;
}

/**
 * Currently only date type can be an object in Metadata values.
 */
export const isDateRange = (obj: FieldValue): obj is DateRange => {
  if (obj === null || !isObject(obj) || Array.isArray(obj)) return false;
  return true;
};

// TODO - instead of guessing the values, we should use the schemas to resolve
// the field types.
const process = (
  metadata: Metadata,
  getRollingDateTime: (rollingDate?: string) => string[] | undefined,
): SearchMetadata => {
  const copy: SearchMetadata = {};
  Object.entries(metadata).forEach(([key, value]) => {
    if (typeof value === 'string') {
      if (value.trim().length > 0) copy[key] = value;
    } else if (isDateRange(value)) {
      const [from, to] = getRollingDateTime(value.rollingDate) ?? [];
      copy[key] = {
        from: from ?? value.startDate,
        to: to ?? value.endDate ?? value.startDate,
      };
    } else if (Array.isArray(value) && value.length > 0) {
      if (isMiniMember(value[0])) {
        copy[key] = value[0].id;
      } else {
        copy[key] = value;
      }
    } else if (value !== null && value !== undefined) {
      copy[key] = value;
    }
  });
  return copy;
};

const getMDFfilter = (
  forceMTypes: SearchItemTypeEnum[],
  metadataFilter: Metadata,
  getRollingDateTime: (rollingDate?: string) => string[] | undefined,
) => {
  if (isEmpty(metadataFilter)) return undefined;
  if (forceMTypes.length > 0) {
    const mdFilter: Record<string, SearchMetadata> = {};
    for (const mType of forceMTypes) {
      mdFilter[mType] = process(metadataFilter, getRollingDateTime);
    }
    return JSON.stringify(mdFilter);
  }
  return undefined;
};

export const convertToRestrictedOnlyTypes = (
  mTypes: SearchItemTypeEnum[],
): SearchItemTypeEnum[] => {
  return mTypes.map((type) => replacementTypes[type] ?? type);
};

export const getSortByFilter = (sortBy: SortOption, order: SortDirection) => {
  if (sortBy === 'best') return undefined;
  const SortByInputs = [{ fieldName: sortFields[sortBy], order: order }] satisfies SortByInput[];
  if (sortBy !== 'updatedAt') SortByInputs.push({ fieldName: sortFields.updatedAt, order: 'desc' });
  return SortByInputs;
};

export const getInput = (
  searchString: string,
  searchableKey: SearchableKeys | undefined,
  state: CommandToolbarProps,
  perPagelimit: number,
  metadataFilter: Metadata,
  mdfId: string | undefined,
  forceMetadataMTypes: SearchItemTypeEnum[] | undefined,
  mIds: string[] | undefined,
  aggregations: AggregateByInput[] | undefined,
  getRollingDateTime: (rollingDate?: string) => string[] | undefined,
): SearchItemInput => {
  const {
    rollingDate,
    rangeBy,
    semanticSearch,
    statusFilter,
    mTypes,
    platformTypes,
    planningItems,
    sortBy,
    order,
    assignedIds,
    createdByIds,
    isScheduled,
    matchAllAssignees,
    showRestricted,
    restrictedItemsOnly,
    priorities,
    fuzzy,
  } = state;
  const derivedSearchString = searchString.length > 0 ? searchString : undefined;

  const [from, to] = getRollingDateTime(rollingDate) ?? [];
  const updatedRangeBy = from && to ? { [sortBy]: { from, to } } : rangeBy;

  return {
    perPagelimit,
    mdfId,
    mTypes: restrictedItemsOnly ? convertToRestrictedOnlyTypes(mTypes) : mTypes,
    rangeBy: updatedRangeBy ?? undefined,
    searchString: !searchableKey ? derivedSearchString : undefined,
    mStates: statusFilter.length ? statusFilter : undefined,
    sortBy: getSortByFilter(sortBy, order),
    assignedMemberIds: !matchAllAssignees && assignedIds.length ? assignedIds : undefined,
    assignedMemberIdsMustOccur: matchAllAssignees && assignedIds.length ? assignedIds : undefined,
    createdByIds: createdByIds.length ? createdByIds : undefined,
    metaDataFilter: forceMetadataMTypes?.length
      ? '{}'
      : JSON.stringify(process(metadataFilter, getRollingDateTime)),
    metaDataFilterByType: forceMetadataMTypes?.length
      ? getMDFfilter(forceMetadataMTypes, metadataFilter, getRollingDateTime)
      : undefined,
    platformTypes: platformTypes?.length ? platformTypes : undefined,
    referenceIds: planningItems?.length ? planningItems : undefined,
    semanticSearch: semanticSearch === true ? true : undefined,
    ...(searchableKey && { [searchableKey]: derivedSearchString }),
    isScheduled,
    showRestricted: showRestricted || restrictedItemsOnly,
    ...(mIds?.length && { mIds }),
    aggregateBy: aggregations?.length ? aggregations : undefined,
    priorities,
    fuzzySearch: fuzzy,
  };
};
