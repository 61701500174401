import { ErrorMap } from 'hooks/useMdfErrorMap';

import { Metadata } from './forms/forms';
import { EditorCommandConfigType } from './memberTypes/editorCommands';
import { ColorVariants } from './colors';
import { EditorValue } from './editor';
import {
  ConfigType,
  Mdf,
  MdfField,
  MemberType,
  MemberTypeEnum,
  MProperties,
  NotePropertiesInput,
  PlatformStructure,
  ProviderInput,
  Recurrence,
} from './graphqlTypes';
import { WithOptional, WithRequired } from './utils';

export type SupportedMetadataTypes =
  | MemberTypeEnum.Story
  | MemberTypeEnum.ResStory
  | MemberTypeEnum.ArchivedStory
  | MemberTypeEnum.ArchivedResStory
  | MemberTypeEnum.Pitch
  | MemberTypeEnum.ResPitch
  | MemberTypeEnum.ArchivedPitch
  | MemberTypeEnum.ArchivedResPitch
  | MemberTypeEnum.Rundown
  | MemberTypeEnum.User
  | MemberTypeEnum.Contact;

export const MTypeToMdfId: Record<SupportedMetadataTypes, MdfId> = {
  [MemberTypeEnum.Contact]: 'contact-mdf',
  [MemberTypeEnum.Rundown]: 'rundown-mdf',
  [MemberTypeEnum.Story]: 'story-mdf',
  [MemberTypeEnum.ResStory]: 'story-mdf',
  [MemberTypeEnum.ArchivedStory]: 'story-mdf',
  [MemberTypeEnum.ArchivedResStory]: 'story-mdf',
  [MemberTypeEnum.Pitch]: 'pitch-mdf',
  [MemberTypeEnum.ResPitch]: 'pitch-mdf',
  [MemberTypeEnum.ArchivedPitch]: 'pitch-mdf',
  [MemberTypeEnum.ArchivedResPitch]: 'pitch-mdf',
  [MemberTypeEnum.User]: 'user-mdf',
};

export const MdfIds = ['story-mdf', 'rundown-mdf', 'user-mdf', 'contact-mdf', 'pitch-mdf'] as const;
export type MdfId = (typeof MdfIds)[number];
export type ParsedMemberType = Omit<MemberType, 'metadata'> & {
  metadata: Metadata;
  mId: string;
  mdf?: Mdf;
  errorMap?: ErrorMap;
  validFieldMap?: Record<string, MdfField>;
  commandData?: EditorCommandConfigType;
};

export type AssignedMember = Pick<
  WithRequired<MemberType, 'mId' | 'mRefId' | 'mType' | 'mTitle' | 'mProperties' | 'metadata'>,
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mTitle'
  | 'mAvatarUrl'
  | 'mDescription'
  | 'mProperties'
  | 'mAvatarKey'
  | 'metadata'
> & {
  mProperties: UserMProperties;
  avatarUrl?: string;
  isCreator?: boolean;
};

export interface MId {
  mId: string;
}

export interface MIdRefId {
  mId: string;
  mRefId: string;
}

type FeaturePermission = {
  name: string;
  groups: string[];
};
export interface MemberPermissions {
  read: [FeaturePermission];
  write: [FeaturePermission];
}

export const isString = (payload: unknown): payload is string => {
  return typeof payload === 'string';
};

export const isObject = (obj: unknown): obj is object => {
  return typeof obj === 'object';
};

export const hasMID = (obj: unknown): obj is MId => {
  return isObject(obj) && typeof (obj as Partial<MId>).mId == 'string';
};

export const isAssignedMember = (obj: unknown): obj is AssignedMember => {
  if (!isObject(obj)) return false;
  const probablyMember = obj as Partial<AssignedMember>;
  const { mType, mTitle, mId } = probablyMember;
  return (
    mType === ('user' as MemberTypeEnum) && typeof mTitle === 'string' && typeof mId === 'string'
  );
};
export type Story = Pick<
  WithRequired<
    MemberType,
    | 'mId'
    | 'mRefId'
    | 'mType'
    | 'mTitle'
    | 'mContentKey'
    | 'mAssignedMembers'
    | 'mSyncProviders'
    | 'mSyncActive'
    | 'mAvatarKey'
    | 'mThumbnailKey'
    | 'mUpdatedAt'
    | 'mCreatedAt'
    | 'metadata'
  >,
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mTitle'
  | 'mDescription'
  | 'mContentKey'
  | 'mContentUrl'
  | 'mSyncProviders'
  | 'mSyncActive'
  | 'mAvatarKey'
  | 'mThumbnailKey'
  | 'mUpdatedAt'
  | 'mCreatedAt'
  | 'mThumbnailUrl'
  | 'mAvatarUrl'
  | 'mPublishingAt'
  | 'mPublishingEnd'
  | 'mMetaData'
  | 'mState'
  | 'metadata'
  | 'ttl'
  | 'locked'
  | 'mUpdatedById'
  | 'mLastVersion'
  | 'mPriority'
> & {
  __typename?: 'RestrictedErrorType' | 'MemberType';
  mdfId?: string;
  mAssignedMembers: AssignedMember[];
};

export type Instance = Pick<
  WithRequired<
    MemberType,
    | 'mId'
    | 'mRefId'
    | 'mType'
    | 'mTitle'
    | 'mMetaData'
    | 'mProperties'
    | 'locked'
    | 'mCreatedById'
    | 'mCreatedAt'
    | 'mUpdatedAt'
    | 'mAssignedMembers'
    | 'mState'
    | 'mStoryId'
    | 'mUpdatedById'
  >,
  | '__typename'
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mTitle'
  | 'mMetaData'
  | 'mProperties'
  | 'locked'
  | 'mCreatedById'
  | 'mCreatedAt'
  | 'mUpdatedAt'
  | 'mAssignedMembers'
  | 'items'
  | 'mState'
  | 'mStoryId'
  | 'mUpdatedById'
  | 'mContentKey'
  | 'mContentUrl'
  | 'mDefaultContentKey'
  | 'mPublishingAt'
  | 'mPublishingEnd'
  | 'mThumbnailUrl'
  | 'mThumbnailKey'
  | 'mDescription'
  | 'mRelatedMembers'
  | 'isRestricted'
  | 'mAvatarKey'
  | 'mRundownTemplateId'
  | 'mTemplateId'
  | 'isTemplateInstance'
  | 'metadata'
> & {
  mProperties: {
    platform: string;
    account?: PlatformAccount;
    id?: string;
    platformKind?: string;
    platformIcon?: string;
    provider?: {
      embeddedEndpoint: string;
      message: string;
      previewEndpoint: string;
      requestId: string;
      state: string;
      updateEndpoint: string;
    };
    platformStructure?: {
      id: string;
      name: string;
      variant: string;
      sections: {
        blocks: string[];
        id: string;
        name: string;
      }[];
      config: {
        block: string;
        mMetaData: {
          key: string;
          value: string;
        }[];
      };
      allowVideoInPhotogallery: boolean;
    };
  };
};

export interface InstanceWithContent extends Instance {
  mContent?: EditorValue | null;
}

export type Note = Pick<
  WithRequired<
    MemberType,
    | 'mId'
    | 'mRefId'
    | 'mType'
    | 'mContentKey'
    | 'mDefaultContentKey'
    | 'mCreatedAt'
    | 'mUpdatedAt'
    | 'mStoryId'
  >,
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mTitle'
  | 'mContentKey'
  | 'mDefaultContentKey'
  | 'mCreatedAt'
  | 'mUpdatedAt'
  | 'mDescription'
  | 'locked'
  | 'mStoryId'
  | 'mUpdatedById'
  | 'mLastVersion'
> & {
  mProperties?: Omit<NotePropertiesInput, '__typename'>;
};

export type Asset = Pick<
  WithRequired<MemberType, 'mId' | 'mRefId' | 'mAssetId' | 'mContentKey' | 'mMetaData' | 'mType'>,
  | 'mId'
  | 'mRefId'
  | 'itemType'
  | 'mAssetId'
  | 'mContentKey'
  | 'mContentUrl'
  | 'mMetaData'
  | 'mThumbnailUrl'
  | 'mType'
  | 'mediaType'
  | 'mUpdatedAt'
  | 'mTitle'
>;

export type PanelType = Pick<MemberType, 'mTitle' | 'mId' | 'mRefId' | 'configs'> & {
  mTitle: string;
  mId: string;
  mRefId: string;
  configs: ConfigType[];
};
export type OtherPlatform = Pick<
  WithRequired<MemberType, 'mRefId' | 'mTitle' | 'mProperties'>,
  'mRefId' | 'mTitle' | 'mProperties' | 'workflowSettings'
> & {
  mProperties: MProperties & {
    platform: string;
    accounts?: PlatformAccount[];
    platformKind?: string;
    provider?: ProviderInput;
    platformIcon?: string;
    platformStructure?: PlatformStructure;
  };
};

export const isLinearPlatform = (obj: Platform): obj is LinearPlatform => {
  return obj?.mProperties?.platform === 'linear';
};

export type LinearPlatform = WithOptional<OtherPlatform, 'mRefId'> & { id: string };

export type Platform = OtherPlatform | LinearPlatform;

export interface PlatformAccount {
  accountUrl: string;
  accountLogo?: string;
  accountTitle: string;
  accountId: string | null;
  accountRefId?: string | null;
  recurrence?: Recurrence;
  isUnassigned?: boolean;
  rundownTemplateId?: string;
  orderType?: string;
  variant?: string;
}

export type UserMProperties = {
  firstName: string;
  surname: string;
  email: string;
  phone?: string;
  dateOfBirth?: string;
  jobTitle?: string;
  readSpeed?: number;
  notListed?: boolean;
  username?: string;
  __typename?: 'ContactType';
};

export type UserBookmark = {
  bookmarkedId: string;
  bookmarkedType: string;
};

export type User = Pick<
  WithRequired<MemberType, 'mId' | 'mRefId' | 'mType' | 'mTitle' | 'metadata'>,
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mTitle'
  | 'mAvatarKey'
  | 'mAvatarUrl'
  | 'mMetaData'
  | 'mBookmarks'
  | 'mDescription'
  | 'mProperties'
  | 'mdfId'
  | 'metadata'
> & {
  mProperties: UserMProperties;
  groups?: string[];
  avatarUrl?: string;
  attributes?: User;
};

export type KanbanBoardStateType = {
  id: string;
  name: string;
  description: string;
  backgroundColor: keyof ColorVariants;
  icon: string;
};

export type DailyNote = Pick<
  WithRequired<MemberType, 'mId' | 'mRefId'>,
  | 'mId'
  | 'mRefId'
  | 'mType'
  | 'mContentKey'
  | 'locked'
  | 'mUpdatedAt'
  | 'mCreatedAt'
  | 'mUpdatedById'
> & { mType: MemberTypeEnum.DailyNote };

export interface Version {
  id?: string;
  title?: string;
  type?: string;
  eventId: string;
  timestamp?: string;
  updatedBy?: string;
  updatedById?: string;
  contentKey?: string;
  actionId?: 'version' | 'stateChanged' | 'created' | 'assigned';
  labels?: (string | undefined)[];
  oldState?: string;
  newState?: string;
}

export const isMemberType = (obj: unknown): obj is MemberType => {
  if (!isObject(obj)) return false;

  return (
    ('__typename' in obj && obj.__typename === 'MemberType') ||
    ('mType' in obj && Object.values(MemberTypeEnum).includes(obj.mType as MemberTypeEnum))
  );
};

export type Template = Pick<
  MemberType,
  'mId' | 'mRefId' | 'mTitle' | 'mDescription' | 'mProperties' | 'mContentKey'
>;

export type Settings = Pick<WithRequired<MemberType, 'mId' | 'mRefId'>, 'mId' | 'mRefId'> & {
  mMetaData: {
    key: string;
    value: string | number | object | boolean | null | undefined;
  }[];
};

export type SystemSettings = {
  userSettings: Settings;
  generalSettings: Settings;
};

export type Priority = {
  id: string;
  label: string;
  color?: string;
};
