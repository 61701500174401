import { fromUint8Array, toUint8Array } from 'js-base64';
import { ObservableV2 } from 'lib0/observable';
import * as Y from 'yjs';

import { CustomChannel, CustomEventListener } from 'types/customChannel';

export class CustomYjsProvider extends ObservableV2<{
  sync: (connected: boolean) => void;
  update: (update: Uint8Array) => void;
}> {
  readonly #cleanup: () => void;

  readonly #broadcastEvent: (type: string, data?: unknown) => void;

  /**
   * @param {Y.Doc} yDoc
   */
  constructor(yDoc: Y.Doc, channel: CustomChannel) {
    super();

    yDoc.on('update', (update, origin) => {
      // ignore updates applied by this provider
      if (origin !== this) {
        const data = fromUint8Array(update);
        // this update was produced either locally or by another provider.
        //this.emit('update', [update]);
        this.#broadcastEvent('update', data);
      }
    });

    // listen to an event that fires when a remote update is received
    this.on('update', (update: Uint8Array) => {
      Y.applyUpdate(yDoc, update, this); // the third parameter sets the transaction-origin
    });

    const updateListener: CustomEventListener = (_s, _t, data) => {
      if (typeof data === 'string') this.emit('update', [toUint8Array(data)]);
    };

    channel.addEventListener('update', updateListener);
    this.#cleanup = () => channel.removeEventListener('update', updateListener);
    this.#broadcastEvent = (type, data) => channel.broadcastEvent(type, data);

    setTimeout(() => {
      this.emit('sync', [true]);
    }, 3000);
  }

  destroy(): void {
    super.destroy();
    this.#cleanup();
  }
}
